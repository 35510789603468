@import './../../../variables.scss';

.ProductRangeFilter {
	// .FilterGroup.filter__container {
	// 	padding: 1rem;

	// 	.filter__title {
	// 		padding: 0.5rem 1rem 0 1rem;
	// 	}
	// }

	.filter__item {
		//border: 1px solid $color-grey-0;
		//background-color: rgba(black, 0.02);
		width: 100%;
		padding: 0.5rem;
		border-radius: 1.2rem;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;

		.item__title {
			display: flex;
			flex-direction: row;
			font-size: 1.4rem;
		}

		.buylist-indicator {
			color: $color-buylist-indicator;
			margin: 0 0 0 0.5rem;
			font-size: 1.4rem;
		}
	}
}