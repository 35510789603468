@import './../../../variables';

.DeliveryDateSelectionStep {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 1rem;

	.open-order__info, .delivery-date__invalid {
		width: 100%;
		max-width: 100rem;
		margin: 0 0 1rem 0;
		border-radius: $radius-default;
		color: white;
		padding: 2.5rem;
		font-family: $font-heading;
		font-size: 1.8rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		//font-weight: 500;
		//font-size: 1.6rem;
		animation: appear 0.3s ease-in-out 1;

		.choices {
			font-size: 1.4rem;
			text-transform: uppercase;
			font-weight: 500;
			//font-family: $font-heading;

			.choice {
				border-radius: $radius-default;
				margin: 0 0 0 1rem;
				padding: 1rem 1.5rem;
				cursor: pointer;
				white-space: nowrap;

				&.choice--primary {
					background-color: white;
				}
			}
		}
	}

	.open-order__info {
		background-color: $color-green-1;

		.choices {
			.choice {
				&.choice--primary {
					color: $color-green-1;
				}
			}
		}
	}

	.delivery-date__invalid {
		background-color: $color-orange-1;

		.choices {
			.choice {
				&.choice--primary {
					color: $color-orange-1;
				}
			}
		}
	}

	.calendar-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		max-width: 100rem;
		width: 100%;
		border: 1px solid $color-grey-0;
		padding: 1rem;
		border-radius: $radius-default;

		&.disabled {
			.DatePicker {

				opacity: 0.3;
			}
		}

		.info__selected {
			font-family: $font-heading;
			text-transform: uppercase;
			font-weight: 400;
			font-size: 1.6rem;
			margin: 2rem 0 2rem 0;
			white-space: nowrap;

			.selected__date {
				font-weight: 300;
			}
		}
	}

	.calendar__info {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 1rem 0;
		font-size: 1.6rem;
		width: 100%;
		max-width: 100rem;

		.deadline{
			font-family: $font-main;
			font-size: 1.5rem;
			margin: 1rem 0 0 0;
			font-weight: 400;
			line-height: 1.4;

			padding: 1.4rem 2.5rem;
			border-radius: $radius-default;
			box-sizing: border-box;
			border: 1px solid $color-grey-0;

			transition: .2s;

			&__warning {
				background-color: $color-black-2;
				color: white;
			}

			&::selection, ::selection {
				background-color: rgba(255,255,255,0.9) !important;
				color: $color-black-2;
			}
		}

		.deadlines {
			width: 100%;
			.CharlesTitle {
				width: 100%;
				margin-top: 8rem;
				align-items: center;
				> span:after {
					width: unset;
				}
			}
			ul {
				padding: 0;
				margin: 0;

			}
			li {
				list-style-type: none;
				text-align: center;
			}
		}
	}

	.calendar__legend {
		margin: 4rem 0;

		.legend__list {
			display: flex;
			flex-direction: column;
			width: 100%;

			.legend__item {
				display: flex;
				align-items: center;

				.legend__description {
					font-size: 1.6rem;
					margin: 0 2rem;
					font-family: $font-heading;
					font-weight: 300;
					text-transform: uppercase;
				}
			}
		}
	}

	.promo__spotlight {
		padding: 2rem 0 2rem 0;
		font-family: $font-heading;
		font-size: 4rem;
		color: $color-black-2;
		text-transform: uppercase;
		transform: rotate(-2deg);
		display: flex;
		justify-content: center;
		align-items: center;
		line-height: 1;
		transition: 0.3s $cubic-bezier-1 all;
		position: relative;

		.Promos {
			transform: none;
			line-height: normal;
			height: auto;
			min-height: auto;
			background-color: transparent;
			color: black;
			padding: 1rem;

			.promo__item {
				.promo__link {
					color: $color-red-0;
					margin: 0;

					&.black {
						color: black;
					}
				}
			}
		}

		img.promo__target {
			margin: 0 2rem;
			width: 16rem;
			height: 16rem;

			&.promo__target--date {
				background-color: $color-red-1;
				border-radius: 10rem;
			}
		}

		.spotlight__text-1, .spotlight__text-2 {
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: 27rem;
		}

		.spotlight__text-1 {
			align-items: flex-end;

			.text__top {
				//color: $color-gold-3;
				font-weight: 500;
				font-size: 6rem;
			}

			.text__bottom {
				font-size: 2.8rem;
				font-weight: 500;
				color: $color-promo;
			}
		}

		.spotlight__text-2 {
			.text__top {
				font-weight: 500;
				//color: $color-black-2;
				font-size: 2rem;
			}

			.text__bottom {
				font-size: 4rem;
				font-weight: 500;
				color: $color-promo;
			}
		}

		.promo__arrows {
			position: absolute;
			display: flex;
			flex-direction: column;
			align-items: center;
			top: 5.5rem;
			left: -5rem;
			transform: rotate(30deg);

			img {
				width: 10rem;
				height: auto;
				margin: 0 0 0rem 0;
			}

			.promo__arrow--1 {
				margin-right: 5rem;
				transition: 0.3s $cubic-bezier-1 all 0.1s;
			}

			.promo__arrow--2 {
				transition: 0.3s $cubic-bezier-1 all;
			}

			.promo__arrow--3 {
				margin-right: 4rem;
				transition: 0.3s $cubic-bezier-1 all 0.2s;
			}
		}

		&.inactive {
			.promo__arrows {
				.promo__arrow--1 {
					transform: translate(-50rem, 2rem);
				}

				.promo__arrow--2 {
					transform: translate(-50rem, 2rem);
				}

				.promo__arrow--3 {
					transform: translate(-50rem, 2rem);
				}
			}
		}

		.promo__spotlight {
			.Promos {
				.promo__item {
					.promo__link {
						width: 70%;
					}
				}
			}
		}
	}

	.vacation-reminder {
		display: flex;
		flex-direction: column;
		//align-items: center;
		justify-content: space-between;
		font-size: 1.6rem;
		width: 100%;
		max-width: 100rem;
		padding: 2rem;
		background-color: $color-blue-0;
		color: rgba(white, 1);
		font-family: $font-heading;
		border-radius: $radius-default;
		font-weight: 300;

		.title {
			font-weight: 400;
			font-size: 2rem;
			margin-bottom: 0.5rem;
		}

		.actions {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			margin-top: 2rem;
			
			.Button {
				color: white;
				font-weight: 500;

				&.primary {
					background-color: white;
					color: rgba($color-blue-0, 1);
					box-shadow: none;
				}
			}

			.Button:not(:last-of-type) {
				margin-right: 1rem;
			}
		}
	}
}

@media (max-width: 1320px) {
	.DeliveryDateSelectionStep {
		.calendar-container {
			width: auto;

			.calendar {
				flex-wrap: wrap;
			}
		}
	}
}

@media (max-width: 560px) {
	.DeliveryDateSelectionStep {
		.calendar-container {
			width: 100%;
			padding: 2rem;
		}

		.open-order__info {
			flex-direction: column;
			align-items: flex-start;

			.choices {
				display: flex;
				flex-direction: column-reverse;
				align-items: flex-start;
				margin: 4rem 0 0 0;

				.choice {
					margin: 0;

					&.choice--secondary {
						padding-left: 1rem;
					}
				}
			}
		}
	}
}
