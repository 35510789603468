@import './../../variables.scss';

.TradeFairArticle {
    cursor: default;
    display: flex;
    width: 100%;
    align-items: stretch;
    justify-content: flex-start;
    gap: 0;

    --weeks-width: calc(var(--column-width) * 9 + 0.6rem * 8);

    .ArticleImage {
        flex: 0 0 7.3rem;
        margin: 0 1.2rem 0 0;
        border-radius: 0.6rem;
        overflow: hidden;
        cursor: pointer;
    }

    .left {
        font-family: $font-heading;
        min-width: var(--article-min-width);
        max-width: calc(100% - var(--weeks-width) - 0.6rem);
        display: flex;
        align-items: center;
        border-top: 1px solid #dadada;
        padding-top: 0.6rem;
        padding-right: 1rem;

        .text {
            display: flex;
            flex-direction: column;
            overflow: hidden;
        }

        .title {
            font-size: 1.6rem;
            display: flex;
            gap: 0.6rem;
            align-items: center;
            cursor: pointer;

            svg {
                margin-left: 0;
            }

            .Article__name {
                line-height: inherit;
            }

            .Article__id {
                font-size: 1.6rem;
                white-space: nowrap;
            }
        }

        .Article__history {
            margin-top: 0.5rem;
        }
    }

    .weeks {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: stretch;
        gap: 0.6rem;
        flex: 1;
        border-top: 1px solid #dadada;
        padding-top: 0.6rem;
        // overflow: auto;

        .week-container {
            .week-number {
                display: none;
            }
        }

        .week {
            position: relative;
            overflow: hidden;
            width: var(--column-width);
            flex: 0 0 var(--column-width);
            min-width: var(--column-width);
            border-radius: 1.2rem;
            display: flex;
            height: 100%;
            flex-direction: column;
            align-items: flex-end;
            gap: 0.3rem;
            border: 1px solid #eeeeee;
            background-color: #f8f8f8;

            &.has-quantity {
                background-color: #BDDFC6;
            }

            &.unavailable {
                background: repeating-linear-gradient(-45deg,
                        #f8f8f8,
                        #f8f8f8 0.5rem,
                        #f0f0f0 0.5rem,
                        #f0f0f0 1rem);
                //background-color: #f3f3f3;
                border: none;
                //height: 3.6rem;
                border-radius: 0;
                border-radius: 1.2rem;
                //cursor: not-allowed;
            }

            .quantity__button {
                opacity: 0.4;
                //display: none;
                filter: grayscale(1);
            }

            .QuantityInput.not-null {
                .quantity__button {
                    opacity: 0.6;
                    //display: inherit;
                    filter: grayscale(0);
                }
            }

            &:hover,
            &.focus {
                .QuantityInput .quantity__button {
                    opacity: 1;
                    //display: inherit;
                    filter: grayscale(0);
                }
            }

            .upper {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-end;
                align-items: flex-start;
                margin: 0.3rem;
                gap: 0.65rem;

                .coins {
                    display: flex;
                    flex-flow: row nowrap;
                    gap: 0.65rem;
                    justify-content: flex-end;
                    align-items: center;
                }
            }

            .coins {
                display: flex;

            }

            .info {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-end;
                align-items: flex-start;
                gap: 0.3rem;
                margin: 0.3rem;

                .Badge {
                    --size: 1.4rem;
                    --padding-h: 0rem;
                    --padding-v: 0.6rem;
                    --padding-overlap: 0.6rem;
                    --icon-scale: 0.7;
                }

                .promo {
                    background-color: $color-promo;
                    color: white;
                    padding: 0.4rem 0.8rem;
                    border-radius: 2rem;
                    font-size: 1.4rem;
                    font-family: $font-heading;
                }

                .pending {
                    --secondary-color: #6b6b6b;

                    &.Badge--with-primary .Badge__primary {
                        width: 3rem;
                    }

                    .Badge__secondary {
                        padding-right: 0.8rem;
                    }
                }
            }

        }
    }
}

@media (max-width: 1738px) {
    .TradeFairArticle.nav-expanded {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        // background-color: orange;

        .left {
            width: 100%;
            max-width: 100%;
        }

        .weeks {
            overflow-x: scroll;
            min-width: inherit;
            max-width: 100%;
            border-top: none;

            .week-container {
                display: flex;
                flex-flow: column nowrap;
                justify-content: stretch;
                align-items: flex-start;
                min-height: 10rem;

                .week-number {
                    display: inherit;
                    width: var(--column-width);
                    min-width: var(--column-width);
                    text-align: center;
                    font-size: 1.4rem;
                    text-transform: uppercase;
                    font-family: $font-heading;
                    margin-bottom: 0.3rem;
                }

                .week {
                    flex: 1;
                }
            }
        }
    }
}

@media (max-width: 1478px) {
    .TradeFairArticle.nav-collapsed {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        //background-color: goldenrod;

        .left {
            width: 100%;
            max-width: 100%;
        }

        .weeks {
            overflow-x: scroll;
            min-width: inherit;
            max-width: 100%;
            border-top: none;

            .week-container {
                display: flex;
                flex-flow: column nowrap;
                justify-content: stretch;
                align-items: flex-start;
                min-height: 10rem;

                .week-number {
                    display: inherit;
                    width: var(--column-width);
                    min-width: var(--column-width);
                    text-align: center;
                    font-size: 1.4rem;
                    text-transform: uppercase;
                    font-family: $font-heading;
                    margin-bottom: 0.3rem;
                }

                .week {
                    flex: 1;
                }
            }
        }
    }
}