@import './../../variables.scss';

.Error {
	font-size: 4rem;
	font-family: $font-heading;
	position: absolute;
	overflow: hidden;
	background-color: white;
	color: white;
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	// background: url('sr') no-repeat;
	background: url('./../../images/Bord_404.jpg');
	background-position: center center;
	object-fit: contain;

	.error__content {
		max-width: 40rem;
		max-height: 40rem;
		height: 100%;
		//border: 1px solid white;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-top: -6rem;

		h1 {
			margin: 0 0 1rem 0;
			font-size: 5rem;
			font-weight: 400;
		}

		.error__message {
			font-size: 2.4rem;
			font-weight: 400;
			text-align: center;
		}
	}

	.error__403 {}

	&.Error--medium {
		background: transparent;
		color: $color-black-2;
		height: 14rem;
		position: inherit;
		display: flex;
		flex-direction: row;
		margin: 4rem 0;

		img {
			//flex: 0 1;
			//height: 100%;
			height: 14rem;
			margin: 0 4rem 0 0;
			max-height: 30rem;
		}

		.error__content {
			align-items: flex-start;
			justify-content: flex-start;
			margin-top: 0;
			max-width: inherit;
			max-height: inherit;
			padding: 0 0 2rem 0;

			.error__message {
				text-align: inherit;

				p {
					margin: 0;
				}
			}
		}
	}
}