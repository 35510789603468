@import './../../variables.scss';

.TradeFairBoothHolder {
    padding: 1.2rem;
    overflow: hidden;
}

.TradeFairBooth {
    --column-width: 26rem;
    --article-min-width: 35rem;
    margin-bottom: 5rem;
    gap: 10rem;
    overflow-y: auto;

    .Stand {
        font-family: $font-heading;
        font-size: 2rem;
        text-transform: uppercase;
        margin-bottom: -2rem;
    }

    .CharlesTitle,
    .legend .article,
    .TradeFairArticle .left,
    .total .total-label {
        position: sticky;
        left: 0;
        z-index: 10;
        background-color: white;
    }

    .TradeFairArticle .left {
        max-height: 90px;
    }

    .legend {
        display: flex;
        justify-content: flex-start;


        .weeks {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;
            gap: 0.6rem;

            .week {
                flex: 0 0 var(--column-width);
                width: var(--column-width);
                justify-content: center;

            }
        }

        .week,
        .article {
            flex: 0 0 10rem;
            padding: 2.4rem 0;
            display: flex;
            align-items: center;
            font-family: $font-heading;
            font-size: 1.8rem;
            text-transform: uppercase;
        }

        .article {
            min-width: var(--article-min-width);
            flex: 0 0 var(article-min-width);
        }
    }

    .total {
        display: flex;
        // justify-content: space-between;
        margin: 0.6rem 0;
        flex: 1;

        .total-label {
            min-width: var(--article-min-width);
            border-top: 1px solid #e0e0e0;
            border-bottom: 1px solid #e0e0e0;
        }

        .total-label,
        .week {
            display: flex;
            flex-direction: column;
            font-family: $font-heading;
            font-size: 1.6rem;
            text-transform: uppercase;
            padding: 1.2rem 0.6rem;
            justify-content: center;

            .free {
                color: $color-promo;
            }
        }

        .weeks {
            display: flex;

            gap: 0.6rem;
            border-top: 1px solid #e0e0e0;
            border-bottom: 1px solid #e0e0e0;

            .week {
                flex: 0 0 var(--column-width);
                width: var(--column-width);
                align-items: end;
            }
        }


    }

    .bottom-padding {
        height: 10rem;
    }

    .navigation {
        margin: 1.2rem 0 0 0;
        position: fixed;
        left: var(--width-nav-actual);
        right: 0;
        bottom: 0;
        padding: 1.2rem;
        background-color: white;
        z-index: 2;
        box-shadow: 0 0 1.5rem 0 rgb(0 0 0 / 20%);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 120;

        .middle {
            display: flex;
            align-items: center;
            gap: 1.2rem;

            .total-weight {
                font-size: 1.4rem;
                font-family: $font-heading;
                text-transform: uppercase;

                span {
                    font-size: 1.8rem;
                }
            }
        }

        .middle,
        .nav-left,
        .nav-right {
            //flex: 0 0 30%;
            // overflow: hidden;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            // align-items: center;
            flex-wrap: wrap;
        }

        .nav-left {
            justify-content: flex-start;
            margin-right: auto;
        }

        .nav-right {
            justify-content: flex-end;
            margin-left: auto;
        }
    }
}

@media (max-width: 1738px) {
    .TradeFairBooth.nav-expanded {
        margin-bottom: 16.8rem;
        --article-min-width: 30rem;
        overflow-y: auto;
        // background-color: red;

        .weeks {
            overflow-y: auto;
        }

        .legend {
            display: none;
        }

        .total {
            display: none;
        }
    }
}

@media (max-width: 1478px) {
    .TradeFairBooth.nav-collapsed {
        margin-bottom: 16.8rem;
        --article-min-width: 30rem;
        overflow-y: auto;
        //background-color: yellow;

        .weeks {
            overflow-y: auto;
        }

        .legend {
            display: none;
        }

        .total {
            display: none;
        }
    }
}