@import './../../variables.scss';

.Dropdown {
	font-size: 1.4rem;
	text-transform: uppercase;
	font-family: $font-heading;

	&.Dropdown--disabled {
		.dropdown__selected {
			cursor: default;
		}
	}

	.dropdown__click {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 9;
		display: none;
	}

	.dropdown__container {
		width: 15rem;
		position: relative;
	}

	.dropdown__selected {
		border: 1px solid $color-grey-0;
		padding: 1rem 2rem;
		border-radius: $radius-default;
		width: 100%;
		font-weight: 400;
		cursor: pointer;
		display: flex;
		justify-content: space-between;
		align-items: center;

		svg {
			transition: 0.2s $cubic-bezier-1 all;
		}
	}

	.dropdown__options {
		flex-direction: column;
		font-weight: 300;
		width: 100%;
		padding: 1rem 0;
		border-radius: $radius-default;
		//border-bottom-left-radius: $radius-default;
		//border: 1px solid $color-grey-0;
		position: absolute;
		background-color: white;
		top: calc(100% + 0.5rem);
		box-shadow: $box-shadow-2;
		display: none;
		z-index: 9;
		overflow: hidden;

		.dropdown__option {
			cursor: pointer;
			padding: 0.5rem 2rem;

			&.option--selected {
				font-weight: 400;
			}

			&:hover {
				background-color: $color-grey-0;
			}
		}
	}

	&.dropdown--open {
		.dropdown__click {
			display: inherit;
		}

		.dropdown__selected {

			//border-bottom-right-radius: 0;
			//border-bottom-left-radius: 0;
			svg {
				transform: scaleY(-1);
			}
		}

		.dropdown__options {
			display: flex;
		}
	}
}