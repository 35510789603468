@import './../../variables.scss';

.AppError {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100vh;
	justify-content: center;
	align-items: center;

	.charles-logo {
		width: 50rem;
		margin: 0 0 5rem 0;
	}

	.error__message {
		display: flex;
		flex-direction: column;
		align-items: center;

		h1 {
			font-family: $font-heading;
			font-size: 3.2rem;
			text-transform: uppercase;
			color: $color-black-2;
			font-weight: 500;
			margin: 0;
		}

		h2 {
			font-family: $font-heading;
			font-size: 2rem;
			text-transform: uppercase;
			color: $color-black-2;
			font-weight: 400;
			margin: 0;

			&:after {
				content: none;
			}
		}

		p {
			font-size: 1.6rem;
			max-width: 60rem;
			margin: 1rem 0 0 0;
		}
	}
}
