@import './../../variables.scss';

.Pillbox {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	button {
		flex: 1 1 0px;
		border: 1px solid $color-grey-0;
		background-color: white;
		padding: 1rem;
		border-radius: $radius-quantity-input;
		cursor: pointer;
		text-transform: uppercase;
		font-family: $font-heading;
		font-size: 1.6rem;
		transition: 0.2s $cubic-bezier-1 all;

		&+button {
			margin-left: 2rem;
		}

		&:hover,
		&.selected {
			box-shadow: $box-shadow-2;
		}

		&.selected {

			border: 1px solid $color-black-0;
			color: white;
			background-color: $color-black-0;
		}

		&:hover {
			border: 1px solid transparent;
		}
	}
}