@import './../../../variables.scss';

.ProductBadges {
	display: flex;
	list-style: none;
	padding: 0;
	font-family: $font-heading;

	.product__badge {
		background-color: $color-badge-default;
		color: white;
		font-size: 1.2rem;
		margin: 0 0.5rem 0 0;
		padding: 0.5rem 0.8rem;
		text-transform: uppercase;
		border-radius: $radius-badges;
		cursor: default;
		display: flex;
		justify-content: center;
		align-items: center;

		&.product__badge--eob {
			background-color: $color-eob;
		}

		&.product__badge--season {
			background-color: $color-season;
		}

		&.product__badge--time-sensitive {
			background-color: $color-time;
		}

		&.badge--with-secondary {
			padding: 0;
			background-color: inherit;

			.badge {
				padding: 0.5rem 0.8rem;
				border-radius: $radius-badges;
				background-color: $color-eob;
				z-index: 1;
			}

			.secondary {
				padding: 0.5rem 0.8rem;
				background-color: lighten($color-eob, 15);
				border-radius: $radius-badges;
				margin-left: -3rem;
				padding-left: 3.5rem;

				svg {
					margin-right: 0.3rem;
				}
			}
		}
	}
}