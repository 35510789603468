@import './../../../variables.scss';

.AllergensFilter {
	.filter__item {

		img,
		svg {
			width: 2rem;
			height: 2rem;
			margin: 0 0.7rem 0 0;
			//filter: grayscale(100%);
			transition: 0.3s $cubic-bezier-1 all;
		}

		.allergen__number {
			display: none;
			margin: 0 0.5rem 0 0;
		}

		&.filter--true {
			font-weight: inherit;
		}

		&.filter--false {
			text-decoration: line-through;

			svg {
				filter: grayscale(100%);
			}
		}
	}
}

@keyframes allergen-appear {
	0% {
		//transform: scaleY(0);
		height: 0;
	}

	100% {
		//transform: scaleY(1);
		height: 2rem;
	}
}
