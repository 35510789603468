.GiftCard {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;

    .card-holder {
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;
        border-radius: 3rem;
    }

    .card {
        width: 25rem;
        height: 40rem;
        border-radius: inherit;
        transform-style: preserve-3d;
        transform: translate3d(0, 0, 0);
        animation: .65s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal both running backflip;
        transition: all .4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        cursor: pointer;

        .front_text {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            // flex-flow: column nowrap;
            justify-content: space-between;
            align-items: center;
            // padding: 3rem;
            // border-radius: inherit;
            font-size: 2rem;
            //  background-color: pink;


            .title {
                font-size: 2.3rem;
                font-weight: 700;
                margin-bottom: 1rem;
                color: white;
                padding: 1.4rem;
                text-shadow: 0 0 0.65rem rgba(0, 0, 0, 0.15);
            }

            .bottomContainer {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 1.6rem;
                width: 100%;
                //padding: 1rem;
                //background-color: black;

                .subText {
                    font-size: 1.5rem;
                    font-weight: 500;
                    bottom: 0px;
                    display: flex-end;
                    color: white;
                    text-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.25);
                    width: 100%;
                    padding: 1rem;
                    background: linear-gradient(to bottom right, rgba(0, 0, 0, 0), rgba(255, 51, 0, 1));
                    text-align: center;
                }
            }



        }

        .imgCover {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .face {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            overflow: hidden;
            box-shadow:
                0 1px 1px hsl(0deg 0% 0% / 0.075),
                0 2px 2px hsl(0deg 0% 0% / 0.075),
                0 4px 4px hsl(0deg 0% 0% / 0.075),
                0 8px 8px hsl(0deg 0% 0% / 0.075),
                0 16px 16px hsl(0deg 0% 0% / 0.075);
            border-radius: inherit;
        }

        .front {
            backface-visibility: hidden;
            // z-index: 20;
        }

        .back {
            background: #F0EACB;
            backface-visibility: hidden;
            transform: rotateY(180deg);
            z-index: 1;
            // padding: 2rem;
            // font-size: 14px;
            // line-height: 120%;
            // overflow: scroll;

            // .head {
            //     font-size: 1.2rem;
            //     font-weight: 700;
            //     margin-bottom: 1rem;
            // }

            // .body {
            //     font-size: 1.1rem;
            //     font-weight: 500;
            // }

            // .footer {
            //     font-size: 1rem;
            //     font-weight: 400;
            //     margin-top: 1rem;
            // }
        }
    }

    .actions {
        margin-top: 2rem;
    }

    &.flipped .card {
        animation: .4s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal both running flip;
    }

    .card-holder:hover .card {
        box-shadow:
            0 1px 1px hsl(0deg 0% 0% / 0.1),
            0 2px 2px hsl(0deg 0% 0% / 0.1),
            0 4px 4px hsl(0deg 0% 0% / 0.1),
            0 8px 8px hsl(0deg 0% 0% / 0.1),
            0 16px 16px hsl(0deg 0% 0% / 0.1);
    }

    @keyframes flip {
        0% {
            -webkit-transform: rotateY(0);
            transform: rotateY(0);
        }

        100% {
            -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
        }
    }

    @keyframes backflip {
        0% {
            -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
        }

        100% {
            -webkit-transform: rotateY(0);
            transform: rotateY(0);
        }
    }
}