@import './../../../variables.scss';

.ShopcodeBrowser {
	margin-left: 3rem;
	margin-top: 1rem;

	.shopcode {
		font-family: $font-main;
		cursor: pointer;
		color: $color-grey-2;
		line-height: 1.2;
		margin: 0.7rem 0;
		text-transform: lowercase;
		font-size: 1.3rem;
		font-weight: 400;

		.shopcode__content {
			//border-bottom: 2px solid $color-grey-1;
			//border-left: 1px solid $color-grey-1;
			padding-left: 1rem;
			margin-left: 0.6rem;
		}

		.shopcode__title {
			line-height: 1;

			svg {
				margin-right: 0.5rem;
				font-size: 1.4rem;
			}
		}

		&.shopcode--inactive>.shopcode__title {
			color: $color-grey-2;
		}

		&.shopcode--active>.shopcode__title {
			color: $color-black-2;
			text-decoration: underline;
			font-weight: 600;
		}

		&:hover>.shopcode__title {
			color: $color-black-2;
			text-decoration: underline;
		}

		&.shopcode--level-1 {
			//border: none;
		}

		&.shopcode--level-2,
		&.shopcode--level-3,
		&.shopcode--level-4 {
			//margin-left: 1rem;
		}
	}

}