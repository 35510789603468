@use '../../variables' as *;

.panel {
	padding: 2rem 4rem;
	background-color: white;
	max-width: 100rem;
	margin: 0 auto;
	font-family: $font-heading;
	border: 1px solid $color-grey-0;
	border-radius: $radius-default;

	h1 {
		font-weight: 400;
		font-size: 2.4rem;
		color: $color-black-2;
		text-transform: uppercase;
	}
}