@import './../../variables.scss';

.OrderHistory {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 140rem;
	padding: 0 4rem;
	.List .list__wrapper {
		.list__header {
			grid-template-columns: 22rem 18rem 19rem 20rem 17rem 5rem 1fr;
		}
		.list__body {
			grid-template-columns: none;
			.list__item {
				grid-template-columns: 22rem 18rem 19rem 20rem 17rem 5rem 1fr;
			}
		}
	}

	.open-order {
		border: 1px solid $color-grey-0;
		border-radius: $radius-default;
		padding: 1.2rem;
		margin-bottom: 5rem;
		background-color: rgba(236, 236, 236, 0.2);

		.open-order__description {
			font-family: $font-main;
			font-size: 1.6rem;
			margin-bottom: 1rem;
		}

		.summary {
			background-color: white;
			border: 1px solid $color-grey-0;
			border-radius: $radius-default;
			margin-bottom: 1rem;

			.ProductsSummary .product__item {
				padding: 1rem 2rem 1rem 1rem;

				.item__image img {
					width: 5rem;
				}
			}
		}

		.actions {
			.Button {
				margin-right: 1rem;
			}
		}
	}

	.new-order {
		margin-bottom: 2rem;

		.Button {
			font-size: 1.6rem;
		}
	}

	.error-message {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-size: 2.4rem;
		font-weight: 300;
		font-family: 'Open Sans', sans-serif;

		span[role="img"] {
			font-size: 4rem;
		}
	}

	.List .list__wrapper {
		margin-bottom: 6rem;
		grid-template-columns: none;

		.list__item {
			.deliveryDate {
				font-weight: 500;
				
				svg {
					margin-left: 1rem;
					color: $color-green-0;
				}
			}

			.pdf {
				color: $color-pdf;
			}

			.status {
				color: rgba(55, 55, 53, 0.5);
			}

			.origin, .status {
				font-family: $font-main;
				font-size: 1.4rem;
			}
		}

		.list__header {
			font-size: 1.6rem;
		}
	}
}