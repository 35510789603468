@import './../../variables.scss';

.ProductsView {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;

	.no-products {
		font-size: 2rem;
		text-transform: uppercase;
		font-family: $font-heading;
		font-weight: 300;
	}

	.full-range-hint {
		display: flex;
		flex-direction: column;
		height: 100%;
		align-items: center;

		p {
			font-size: 1.4rem;
			margin-top: 6rem;
		}
	}

	.products__more {
		border: none;
		background-color: $color-black-2;
		width: 30rem;
		margin: 4rem 0;
		color: white;
		text-transform: uppercase;
		font-family: $font-heading;
		font-size: 2rem;
		border-radius: $radius-default;
		padding: 1rem 0;
		cursor: pointer;
	}
}
