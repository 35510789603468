@import './../../../variables.scss';

.TransportDocuments {
	width: 100%;
	max-width: 100rem;

	.Spinner {
		margin-top: 2rem;
	}

	.Search {
		padding: 2rem 0 0 0;
		position: sticky;
		top: 11rem;
		z-index: 2;
		background-color: white;
	}

	.documents {
		margin: 1rem 0 2.5rem 0;

		.documents__details {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			font-size: 1.6rem;
			font-family: $font-heading;
			font-weight: 300;
			margin: 1rem 2.5rem;
		}

		.documents__header {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			width: 100%;
			padding: 1rem 2.5rem;
			font-family: $font-heading;
			font-size: 2rem;
			text-transform: uppercase;
			position: sticky;
			top: 20rem;
			background-color: white;
			z-index: 2;
		}

		.documents__list {
			margin: 1rem 0 2.5rem 0;
			
			.documents__group {
				.group__header {
					font-family: $font-heading;
					font-size: 1.6rem;
					text-transform: uppercase;
					padding: 0 2.5rem 1rem 2.5rem;
					position: sticky;
					top: 14rem;
					background-color: white;
					display: flex;
					flex-direction: row;
					align-items: center;

					.highlighted {
						font-weight: 300;

						.highlight {
							font-weight: 400;
						}
					}

					.group__num {
						font-weight: 300;
						margin: 0 0 0 1rem;
						font-size: 1.3rem;
					}
				}

				.documents__list {
					padding: 0;
					display: flex;
					flex-direction: column;
					border: 1px solid $color-grey-0;
					border-radius: $radius-default;
					font-weight: 300;
					cursor: pointer;

					.document {
						padding: 0;
						list-style-type: none;
						align-items: center;
						border-bottom: 1px solid $color-grey-0;
						font-size: 1.6rem;
						font-family: $font-heading;

						display: grid;
						grid-template-columns: repeat(4, 1fr);
						width: 100%;
						padding: 1.5rem 2.5rem;

						&:first-of-type {
							border-top-right-radius: $radius-default;
							border-top-left-radius: $radius-default;
						}

						&:last-of-type {
							border-bottom: none;
							border-bottom-right-radius: $radius-default;
							border-bottom-left-radius: $radius-default;
						}

						&:nth-child(even) {
							background-color: rgba($color-grey-0, 0.2);

							&:hover {
								background-color: rgba($color-grey-0, 0.4);
							}
						}

						&:hover {
							background-color: rgba($color-grey-0, 0.1);
						}

						.item__date {

						}

						.item__number {

						}

						.item__order-number {

						}

						.item__download {
							text-align: right;
							color: $color-pdf;
							font-size: 1.6rem;
						}
					}
				}
			}
		}
	}
}
