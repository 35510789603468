//@import './../../../index.scss';

.Campaigns {
    position: relative;
    width: 100%;
    max-width: 100rem;
    margin: 3rem auto 5rem;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;

    .Spinner {
        width: max-content;
        margin: 10rem;
    }

    p.desc {
        font-size: 1.5rem;
        max-width: 650px;
    }

    p.green {
        color: green;
    }

    .campaign-header {
        position: relative;
        width: 100%;
        flex: 1 1 100%;
        text-align: center;
        //	z-index: 10;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;

        img {
            margin-top: 2rem;
            display: block;
            min-width: 360px;
            width: 100%;
            max-width: 65rem;
            height: auto;
            object-position: center center;
        }

        p.intro {
            font-size: 1.5rem;
            max-width: 650px;
        }
    }

    h2 {
        text-transform: uppercase;
        font-size: 2rem;
        font-weight: 400;
        margin: 0 0 1rem 0;
    }

    .intro {
        // font-family: "Oswald", sans-serif;
        max-width: 650px;
        font-size: 1.4rem;
        margin: 0 0 2rem 0;
    }

    .intro_second {
        // font-family: "Oswald", sans-serif;
        max-width: 650px;
        font-size: 1.2rem;
        // margin: 0 0 2rem 0;
    }

    .simulate {
        min-width: 360px;
        width: 100%;
        max-width: 65rem;
        padding: 1.5rem 2rem;
        border: 1px solid lightgrey;
        border-radius: 1rem;
        margin: 3.5rem auto 0;

        h2 {
            text-align: center;
        }

        form {
            width: 100%;
            display: flex;
            flex-flow: column nowrap;
            align-items: stretch;
            margin: 3rem auto 1rem;
            gap: 2rem;

            .input-group {
                flex: 1;
                display: flex;
                flex-flow: column nowrap;
                justify-content: flex-start;
                align-items: stretch;

                &+& {
                    margin-top: 5rem;
                }

                label {
                    grid-area: label;
                    font-family: "Oswald", sans-serif;
                    text-transform: uppercase;
                    font-size: 1.6rem;
                    font-weight: 400;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    color: #333;
                    padding-bottom: 0.5rem;
                }

                .input,
                textarea {
                    flex: 1 1 auto;
                    border-radius: 0.6rem;
                }

                textarea {
                    resize: none;
                }

                .Input {
                    &:hover {
                        border-color: rgba(0, 0, 0, 0.87);
                    }

                    &:focus,
                    &:focus-visible {
                        border-color: #1976D2;
                        outline: 1px solid #1976D2;
                    }
                }

                &>input,
                &>textarea,
                &>.MuiSelect-select {
                    border-radius: 0.6rem;
                    border: 1px solid #C6C6C6;
                    min-width: 24rem;
                    width: 100%;
                    outline: none;

                    &:disabled,
                    &.Mui-disabled {
                        color: #C6C6C6 !important;
                        background-color: transparent !important;
                    }
                }

                .input .Mui-disabled {
                    color: #C6C6C6 !important;
                    background: transparent !important;
                }

                input,
                .MuiSelect-select,
                li.MuiButtonBase-root {
                    font-family: "Open Sans", sans-serif !important;
                    font-size: 1.4rem !important;
                    min-height: unset !important;
                }

            }

            .control-group {
                margin-top: 1rem;
                display: flex;
                flex-flow: row wrap;
                justify-content: flex-end;
                align-items: center;
                gap: 1rem;

                .cancel {
                    margin-left: auto;
                }
            }

            button.primary:disabled {
                background: rgb(151, 151, 151);
            }

            button.danger {
                background: #e74c3c;
                color: white;

                &:disabled {
                    background: rgb(51, 51, 51);
                }

                &:hover {
                    background: #ce3d2d;
                }
            }
        }
    }
}