@import './../../variables.scss';

.Search {
	margin: 0;
	width: 100%;
	max-width: 130rem;
	//background-color: white;
	padding: 2rem 0;

	input {
		border: 1px solid $color-grey-0;
		border-radius: $radius-large-input;
		height: 7rem;

		padding: 2rem 3rem;
		font-family: $font-heading;
		font-size: 2rem;
		color: $color-black-2;
		transition: 0.3s $cubic-bezier-1 all;
		background-color: transparent;
		width: 100%;

		&::placeholder {
			color: rgba($color-black-2, 0.5);
		}

		&:focus {
			box-shadow: $box-shadow-2;
			border-color: transparent;
		}
	}
}

@media (max-width: 760px) {
	.Search {
		top: 9rem;
		padding: 2rem 0;
	}
}

@media (max-width: 660px) {
	.Search {
		top: 5rem;

		input {
			font-size: 1.4rem;
			height: 4rem;
		}
	}
}
