@import './../../../variables.scss';

.ProductPromo {
	&.promo__new-products {
		.promo__ribbon {
			color: $color-new-products;
			background: $color-background-new-products;
		}
	}

	.promo__ribbon {
		background-color: $color-promo;
		font-size: 1.4rem;
		padding: 0.5rem;
		color: white;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		text-transform: uppercase;
		transform: rotate(-45deg);
		top: -0.5rem;
		left: -6.5rem;
		width: 15rem;
		height: auto;
		flex-direction: column;

		&.hide {
			opacity: 0.2;
			display: none;
		}

		&.ribbon--free {
			background-color: $color-yellow-0;
			color: $color-black-2;
		}

		.ribbon__body {
			margin: -0.4rem 0 0 0;
		}

		.ribbon__subtext {
			font-size: 1.1rem;
			margin: -0.5rem 0 -0.1rem 0;
		}
	}

	.promo__body {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;

		.promo__description {
			margin: 1rem 0 0 0;
			padding: 1.5rem 2rem;
			border: 1px solid $color-red-0;
			border-top-left-radius: $radius-default;
			border-top-right-radius: $radius-default;

			&.noDuration {
				border-bottom-left-radius: $radius-default;
				border-bottom-right-radius: $radius-default;
			}
		}
	}

	.promo__description {
		font-size: 1.6rem;
		color: $color-red-0;
		font-weight: 300;
		margin: 1rem 0;

		.promo__emphasis {
			font-weight: 400;
		}

		.colli__products {
			margin: 1rem 0 0 0;
		}
	}

	.promo__duration {
		font-size: 1.2rem;
		font-weight: 300;
		text-transform: uppercase;
		display: flex;
		align-items: center;
		background-color: $color-red-0;
		padding: 1rem 2rem;
		border-bottom-left-radius: $radius-default;
		border-bottom-right-radius: $radius-default;
		color: white;

		.duration__text {
			display: flex;
			flex-direction: column;

			.text__top {
				display: flex;
				align-items: center;
			}

			.duration__date {
				font-weight: 500;
				margin: 0 0.3rem;
			}
		}

		.duration__icon {
			margin: 0.5rem 1.5rem 0.5rem 0;
			font-size: 1.6rem;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}