.trade-fair-points {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.15rem;
    justify-content: center;
    align-items: center;

    .disclaimer {
        // font-size: 1.2rem;
        // color: #9D9D9D;
        display: flex;
        flex-direction: row;
    }

    .meta {
        margin-bottom: 0.15rem;
        color: #9D9D9D;
    }
}