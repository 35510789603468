.CRUDDetail {
	width: 100%;
	max-width: 80rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 1rem;
	margin-bottom: 6rem;

	.CharlesTitle {
		font-weight: 400;
		margin-top: 0;
		margin-bottom: 2rem;
	}

	.DynamicForm .form__section h2 {
		//font-size: 2.8rem;
	}

	.actions {
		display: flex;
		column-gap: 1rem;
		row-gap: 1rem;
		flex-wrap: wrap;
	}

	.hints {
		.save-message {
			font-family: 'Oswald', sans-serif;
			margin-bottom: 2rem;
			font-size: 1.6rem;
			
			&.error {
				color: #C1222F;
			}

			&.success {
				color: rgba(0, 177, 106, 1);
			}
		}
	}
}

.PortalModal.delete__confirmation {
	.Button:not(:last-of-type) {
		margin-right: 1rem;
	}
}
