@import './../../variables.scss';

.Buylist {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 0 2rem 0;
	padding: 0 1rem;

	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		max-width: 125rem;
	}

	.download__buttons {
		display: flex;
		margin: 0 0 2rem 0;
		column-gap: 1rem;
	}

	.products__body {
		width: 100%;
		max-width: 130rem;
		display: grid;
		grid-template-areas:
			'filter list';
		grid-template-columns: 25rem auto;
		row-gap: 2rem;
		column-gap: 2rem;

		.ProductsFilters {
			grid-area: filter;
		}

		.products__list {
			grid-area: list;
		}
	}

	.products__filter {
		font-family: $font-heading;
		max-width: 125rem;
		width: 100%;

		.Search {
			position: inherit;
		}
	}

	.products__list {
		max-width: 100rem;
		width: 100%;
	}
}

@media (max-width: 760px) {
	.Buylist {
		.products__body {
			flex-direction: column;

			.ProductsFilters {
				margin-right: 0;
				width: 100%;
			}
		}
	}
}
