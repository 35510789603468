@import './../../variables.scss';

.OrderedProducts {
	width: 100%;
	max-width: 140rem;
	padding: 0 4rem;

	.filters {
		.filter {
			&:not(:last-of-type) {
				margin-right: 1rem;
			}
		}
	}

	.details {
		margin: 1rem 0 2rem 1.5rem;
		font-family: $font-heading;
		font-size: 1.6rem;
		font-weight: 300;
	}

	.List .list__wrapper {
		.list__header {
			grid-template-columns: 10rem 1fr 45rem 5rem;
		}

		.list__header .header__column {
			&.name {
				span {
					display: flex;

					.Spinner {
						margin-left: 1rem;
					}
				}
			}
		}

		.list__body {
			grid-template-columns: none;
			margin-bottom: 4rem;
		}

		.name {
			min-width: 40rem;
		}

		.list__body .list__item {
			grid-template-columns: 10rem 1fr 45rem 5rem;
		}

		.list__body .list__item .item__cell {
			&.name {
				font-weight: 400;

				span {
					display: flex;
					align-items: center;

					.ProductBadges {
						margin-left: 1rem;
					}
				}
			}

			&.entries {
				font-family: $font-main;
				font-size: 1.4rem;
				font-weight: 400;
				max-width: 60rem;
				text-align: right;
				padding-right: 4rem;

				@media(max-width: $screen-xxlm) {
					padding-left: 4.5rem;
				}
			}

			&.quantity {
				font-family: $font-main;
				font-weight: 400;
				font-size: 1.4rem;
			}
		}
	}
}