@import './../../variables.scss';

.Customers {
	max-width: 120rem;
	width: 100%;
	margin: 0 auto 2rem;
	padding: 0 2rem;
	.List {
		.list__wrapper {
			grid-template-columns: none;
			.list__header {
				grid-template-columns: none;
			}
			.list__body {
				grid-template-columns: none;
				@media(max-width: $screen-xxls) {
					margin-right: 2rem;
				}
				.list__item  {
					grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
						.open_order {
							@media(max-width: 67rem) {
								padding-left: 2.5rem;
							}
						}
				}
			}
		}
	}
	.CharlesTitle {
		margin-top: 6rem;
	}
	.message {
		display: flex;
		flex-direction: row;
		align-items: center;
		font-size: 2.4rem;
		font-weight: 300;

		span[role="img"] {
			margin-right: 2rem;
			font-size: 4rem;
		}
	}
}
