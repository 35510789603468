@import './../../../node_modules/react-image-gallery/styles/scss/image-gallery.scss';
@import './../../variables.scss';

.Carousel {
	width: 100%;
	height: 100%;

	.image-gallery {

		.image-gallery-slide-wrapper {
			.image-gallery-icon {
				filter: none;
				color: $color-black-2;
				transform: scale(0.5);
				top: 30%;

				&:hover {
					color: black;
					transform: scale(0.6);
				}
			}

			&>span {
				display: none;

				button {
					font-size: 2rem;
					background-color: white;
					height: 3.2rem;
					width: 3.2rem;
					border-radius: $radius-100percent;
					padding: 0;
					margin: 0 1rem;
					border: 0.2rem solid $color-black-2;
					display: flex;
					justify-content: center;
					align-items: center;

					&.image-gallery-right-nav {
						&::before {
							margin-left: 0.2rem;
						}
					}

					&.image-gallery-left-nav {
						&::before {
							margin-right: 0.2rem;
						}
					}

					&::before {
						color: $color-black-2;
						text-shadow: none;
						display: flex;
						justify-content: center;
						align-items: center;
					}

					&:hover {
						&::before {
							color: $color-black-2;
						}
					}
				}
			}

			&:hover {
				&>span {
					display: inherit;
				}
			}

			.image-gallery-image {
				display: flex;
				justify-content: center;
				align-items: center;

				img {
					max-width: 40rem;
					max-height: 40rem;
					object-fit: cover;
				}
			}
		}

		.image-gallery-bullets {
			bottom: 1rem;

			.image-gallery-bullet {
				border: none;
				background-color: $color-black-2;
				box-shadow: none;
				width: 0.5rem;
				height: 0.5rem;
				padding: 0;

				&.active {
					background-color: $color-black-2;
					position: relative;

					&::before {
						content: '';
						background-color: white;
						width: 0.3rem;
						height: 0.3rem;
						display: block;
						position: absolute;
						border-radius: 2rem;
						left: 1px;
						top: 1px;
					}
				}
			}
		}

		&:hover {
			.image-gallery-bullet {
				border: 0.2rem solid $color-black-2;
				background-color: white;
				width: 1.4rem;
				height: 1.4rem;

				&.active {
					&::before {
						border: 0.2rem solid white;
						background-color: transparent;
						width: 0.6rem;
						height: 0.6rem;
						left: 0px;
						top: 0px;
					}
				}
			}
		}
	}
}