@import './../../variables.scss';

.TradeFairOverview {
    margin: 0 1.2rem 8.2rem 1.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    img.logo {
        width: 24rem;
        margin: 2.4rem;
    }

    .loader {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        h1 {
            font-weight: 400;
            font-family: $font-heading;
            text-transform: uppercase;
        }

        p {
            font-size: 1.4rem;
            font-family: $font-main;
        }
    }

    .booths {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1.2rem;
        max-width: 120rem;
        width: 100%;

        .TradeFairOverviewBooth {
            background-color: #f1f1f1;
            border-radius: $radius-default;

            a {
                display: flex;
                flex-direction: column;
                // justify-content: space-between;
                text-decoration: none;
                padding: 1.2rem;
                width: 100%;
                height: 12rem;
            }

            h3 {
                font-family: $font-heading;
                font-size: 2.2rem;
                font-weight: 400;
                margin: 0;
            }

            .stand {
                font-size: 1.2rem;
                text-transform: uppercase;
            }

            .bottom {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
            }

            .totals {
                display: flex;
                flex-direction: column;
                font-family: $font-main;
                font-size: 1.4rem;
                //margin-top: 1.2rem;
                gap: 0;
            }

            .pending {
                --secondary-color: #6b6b6b;
            }
        }
    }

    .actions {
        margin-top: 1.2rem;
        position: fixed;
        left: var(--width-nav-actual);
        right: 0;
        bottom: 0;
        padding: 1.2rem;
        background-color: white;
        box-shadow: 0 0 1.5rem 0 rgb(0 0 0 / 20%);
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1.2rem;
        z-index: 2;

        .total-weight {
            font-size: 1.4rem;
            font-family: $font-heading;
            text-transform: uppercase;

            span {
                font-size: 1.8rem;
            }
        }
    }

    .submitted {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex-direction: column;

        .checkmark {
            border-right: 1rem solid $color-green-1;
            border-bottom: 1rem solid $color-green-1;
            height: 10rem;
            width: 5rem;
            transform: rotate(40deg);
            margin: 5rem 0;
        }

        .success__message {
            font-family: 'Oswald';
            font-size: 3.2rem;
            text-transform: uppercase;
            text-align: center;
        }
    }

    .TradeFairOverviewBooth {
        .free {
            color: $color-promo;
        }
    }
}

@media (max-width: 1320px) {
    .TradeFairOverview {
        .booths {
            grid-template-columns: 1fr 1fr;
        }
    }
}

@media (max-width: 920px) {
    .TradeFairOverview {
        .booths {
            grid-template-columns: 1fr;
        }
    }
}