@import './../../../variables';

.FinishStep {
	display: flex;
	flex-direction: column;
	align-items: center;

	.info {
		font-family: $font-main;
		font-size: 1.5rem;
		font-weight: 400;
		line-height: 1.4;
		padding: 2.5rem;
		margin: 0 1rem;
		max-width: 80rem;
		border-radius: $radius-default;
		background-color: $color-black-2;
		color: white;
	}

	.form {
		background-color: white;
		max-width: 80rem;
		width: 100%;
		border-radius: $radius-default;
		padding: 2rem 1rem;
		
		.form__control {
			display: flex;
			flex-direction: column;
			font-family: $font-heading;
			text-transform: uppercase;
			font-size: 2.4rem;
			margin: 0 0 2rem 0;
			
			label {
				color: $color-gold-0;
				padding: 0 0 0 1rem;
				margin: 0 0 1rem 0;
				font-weight: 300;
			}

			input, textarea {
				font-family: $font-main;
				font-size: 1.8rem;
				padding: 2rem;
				border: 1px solid $color-grey-0;
				border-radius: $radius-default;
				transition: $cubic-bezier-1 all 0.3s;
				width: 100%;
				
				&:focus {
					border-color: $color-main-light-0;
					//box-shadow: $box-shadow-2;
				}
			}
		}
	}
}
