@use '../../variables' as *;

h2 {
	font-family: $font-heading;
	text-transform: uppercase;
	font-size: 4rem;
	font-weight: 600;
	display: flex;
	flex-direction: column;

	// &::after {
	// 	content: '';
	// 	height: 0.5rem;
	// 	width: calc(100% + 4rem);
	// 	background-color: $color-gold-3;
	// 	transform: rotate(-1deg);
	// 	margin: 0 0 0 -2rem;
	// }
}

th {
	font-weight: inherit;
}

a {
	color: inherit;

	&:visited {
		color: inherit;
	}
}