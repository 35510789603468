@import './../../variables.scss';

.FilterGroup {
	padding: 1.5rem 2rem;
	border: 1px solid $color-grey-0;
	border-radius: $radius-default;
	margin: 0 0 1rem 0;


	.filter__title {
		text-transform: uppercase;
		font-family: $font-heading;
		font-weight: 400;
		font-size: 1.8rem;
		color: $color-black-2;
		margin: 0;
		padding: 0;
		display: flex;
		justify-content: space-between;

		.filter__name {
			display: flex;

			.filter__clear {
				cursor: pointer;
				margin: 0 0 0 1rem;
			}
		}

		.filter__hide {
			cursor: pointer;
		}
	}

	.filter__items {
		margin-top: 1rem;
		max-height: 50rem;
		overflow: hidden;
	}

	.filter__more {
		margin-top: 0.5rem;
		font-family: $font-main;
		font-size: 1.3rem;
		text-decoration: underline;
		color: $color-text-grey-1;
		cursor: pointer;
	}

	.items__group {
		display: flex;
		flex-direction: column;
		margin: 0.4rem 0;
	}

	.filter__item {
		display: flex;
		//align-items: center;
		margin: 0 0 0.4rem 0;
		font-size: 1.3rem;
		cursor: pointer;
		color: $color-black-2;
		font-family: $font-main;
		user-select: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;

		&.filter--undefined {
			color: red;
		}

		&.filter--off,
		&.filter--false {
			color: $color-grey-2;
		}

		&.filter--include,
		&.filter--true {
			font-weight: 600;
		}

		&.filter--disabled {
			color: $color-grey-1;
			//text-decoration: line-through;

			.Spinner {
				width: 2rem;
				margin: 0 1rem 0 0;

				.circle {
					border-bottom-color: $color-grey-1;
					border-right-color: $color-grey-1;
				}
			}
		}

		&.filter--remove {
			display: none;
		}
	}
}