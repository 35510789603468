@import './../../../variables.scss';

.OrderReminder {
	border-radius: $radius-default;
	margin: 1rem 0;
	font-size: 1.5em;
	text-transform: uppercase;
	//box-shadow: $box-shadow-1;
	border: 1px solid $color-gold-0;
}
