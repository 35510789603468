@import './../../../variables.scss';

.Invoices {
	width: 100%;
	padding: 0 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;

	.Spinner {
		margin-top: 2rem;
	}

	p {
		font-size: 1.4rem;
	}
	
	.Search {
		position: sticky;
		top: 11rem;
		padding: 2rem 0 0 0;
		background-color: white;
		z-index: 2;
		max-width: 100rem;

		input {
			width: 100%;
		}
	}

	.invoices__view {
		max-width: 100rem;
		width: 100%;

		.view__details {
			display: grid;
			grid-template-columns: 1fr 1fr;
			font-size: 1.6rem;
			font-family: $font-heading;
			font-weight: 300;
			margin: 1rem 2.5rem;

			.views {
				text-align: right;

				.views__view {
					cursor: pointer;
					margin: 0 0 0 1rem;
					color: $color-grey-1;
					font-size: 2rem;

					&.view--active {
						color: $color-gold-3;
					}
				}
			}
		}

		.list__header {
			display: grid;
			grid-template-columns: repeat(5, 1fr);
			width: 100%;
			padding: 1rem 2.5rem;
			font-family: $font-heading;
			font-size: 2rem;
			text-transform: uppercase;
			position: sticky;
			top: 20rem;
			background-color: white;
			z-index: 1;
		}
	}

	.invoices__list, .groups__list {
		margin: 1rem 0 2.5rem 0;

		.invoice__group {
			.group__header {
				font-family: $font-heading;
				font-size: 1.6rem;
				text-transform: uppercase;
				padding: 0 2.5rem 1rem 2.5rem;
				//position: sticky;
				top: 14rem;
				background-color: white;
				display: flex;
				flex-direction: row;
				align-items: center;

				.highlighted {
					font-weight: 300;

					.highlight {
						font-weight: 400;
					}
				}

				.group__num {
					font-weight: 300;
					margin: 0 0 0 1rem;
					font-size: 1.3rem;
				}
			}
		}

		.list__items {
			padding: 0;
			display: flex;
			flex-direction: column;
			border: 1px solid $color-grey-0;
			border-radius: $radius-default;
			font-weight: 300;
			cursor: pointer;

			.list__item {
				padding: 0;
				list-style-type: none;
				align-items: center;
				border-bottom: 1px solid $color-grey-0;
				font-size: 1.6rem;
				font-family: $font-heading;

				display: grid;
				grid-template-columns: repeat(5, 1fr);
				width: 100%;
				padding: 1.5rem 2.5rem;

				&:first-of-type {
					border-top-right-radius: $radius-default;
					border-top-left-radius: $radius-default;
				}

				&:last-of-type {
					border-bottom: none;
					border-bottom-right-radius: $radius-default;
					border-bottom-left-radius: $radius-default;
				}

				&:nth-child(even) {
					background-color: rgba($color-grey-0, 0.2);

					&:hover {
						background-color: rgba($color-grey-0, 0.4);
					}
				}

				&:hover {
					background-color: rgba($color-grey-0, 0.1);
				}

				.item__invoice {
					text-align: right;
					color: $color-pdf;
					font-size: 1.6rem;
				}
			}
		}
	}

	.invoices__small-view {
		display: flex;
		flex-direction: column;
		width: 100%;

		.invoice__small-item {
			border: 1px solid $color-grey-0;
			border-radius: $radius-default;
			padding: 1rem 2rem;
			width: 100%;
			margin: 0.5rem 0 0 0;
			font-family: $font-heading;
			display: flex;
			justify-content: space-between;

			.item__left, .item__right {
				display: flex;
				flex-direction: column;
			}

			.item__right {
				align-items: flex-end;
				justify-content: space-between;
				padding: 0.5rem 0;
			}
			
			.item__date {
				font-size: 1.4rem;
				color: $color-text-grey-1;
				//margin-bottom: -0.8rem;
			}

			.item__number {
				font-size: 2.4rem;
				font-weight: 600;
				display: flex;
			}

			.item__order-number {
				display: flex;
				text-transform: uppercase;
				font-size: 1.2rem;

				.Highlight {
					margin: 0 0 0 0.2rem;
				}
			}

			.item__amount {
				font-size: 1.8rem;
				font-weight: 500;
			}

			.item__download {
				color: $color-pdf;
				font-size: 1.6rem;
			}

		}
	}
}

@media (max-width: 650px) {
	.Invoices {
		.invoices__list, .groups__list {
			.list__items {
				.list__item {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
				}
			}
		}
	}
}
