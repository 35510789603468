@import '../../../variables';

.Vacation {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

	.description {
		max-width: 80rem;
		font-size: 1.6rem;
		background-color: rgba(#373735, 0.05);
		padding: 2rem;
		border-radius: 1.2rem;
		margin-bottom: 4rem;
		//font-family: $font-heading;
		color: $color-black-2;
	}

	.message.error {
		max-width: 100rem;
		margin-bottom: 2rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.icon {
			font-size: 3rem;
			margin-bottom: 1rem;
		}

		p {
			font-size: 2rem;
			font-family: $font-heading;
			color: $color-black-2;
			font-weight: 300;
			margin: 0;
		}

		&--submit {
			margin: 1rem 0 0 0;
			width: 100%;
			align-items: flex-start;
			
			p {
				color: $color-red-0;
			}
		}
	}

	h3 {
		display: flex;
		justify-content: center;
		width: 100%;
		font-size: 3.6rem;
		font-family: $font-heading;
		font-weight: 400;
		margin: 0 0 2rem 0;
	}

	.legend {
		display: flex;
		flex-wrap: wrap;
		column-gap: 3.6rem;
		row-gap: 2rem;
		margin-bottom: 3.6rem;
		//max-width: 50rem;

		&__item {
			display: flex;
			align-items: center;
			
			.date {
				font-family: $font-heading;
				height: 3rem;
				min-width: 3rem;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 1.4rem;
				border-radius: 0.6rem;
				font-weight: 500;
			}
			
			.text {
				font-size: 1.4rem;
				margin-left: 0.8rem;
				font-family: $font-heading;
			}

			&.today .date {
				background-color: rgba(0, 0, 0, 0.8);
				color: white;
			}

			&.vacation .date {
				background-color: rgba($color-yellow-0, 0.5);
				border-radius: 0;
			}

			&.weekend .date {
				color: rgba(#373735, 0.5);
			}

			&.past .date {
				color: rgba(#373735, 0.15);
			}

			&.delete .date {
				background-color: rgba($color-red-0, 0.25);
				border-radius: 0;
			}

			&.closed .date {
				position: relative;

				&:after {
					content: '';
					position: absolute;
					top: 0.2rem;
					left: -0.3rem;

					border-left: 0.5rem solid transparent;
					border-right: 0.5rem solid transparent;
					border-bottom: 0.5rem solid $color-gold-3;

					transform: rotate(-45deg);
				}
			}
		}
	}

	.date-picker-container {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: center;

		.prev, .next {
			font-size: 3rem;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 8rem;
			height: 8rem;
			border-radius: 8rem;
			background-color: rgba(black, 0.04);
			cursor: pointer;

			&:hover {
				background-color: rgba(black, 0.08);
			}

			svg {
				color: rgba(black, 0.4);
			}
		}

		.prev {
			margin-right: 3.6rem;
		}

		.next {
			margin-left: 2rem;
		}
	}

	.DatePicker {
		margin-bottom: 4rem;
		
		.calendars {
			flex-wrap: wrap;
			max-width: 102rem;
			
			column-gap: 1rem;

			.calendar, .calendar:not(:last-of-type) {
				margin-right: 0;
				margin-bottom: 0;
			}
		}

		.calendar__days .calendar__day {
			user-select: none;

			&.vacation {
				--bg-opacity: 0.5;
				background-color: rgba($color-yellow-0, var(--bg-opacity));
				border-radius: 0;

				&--start, &--end {
					--bg-opacity: 0.75;
				}

				&.past {
					--bg-opacity: 0.3;

					&.vacation--start, &.vacation--end {
						--bg-opacity: 0.45;
					}
				}

				&--start {
					border-top-left-radius: 0.6rem;
					border-bottom-left-radius: 0.6rem;
				}

				&--end {
					border-top-right-radius: 0.6rem;
					border-bottom-right-radius: 0.6rem;
				}

				&.hover {
					--bg-opacity: 0.8;
					background-color: rgba($color-yellow-0, var(--bg-opacity));

					&.vacation--start, &.vacation--end {
						--bg-opacity: 1;
					}

					&--delete {
						--bg-opacity: 0.25;
						background-color: rgba($color-red-0, var(--bg-opacity));

						&.vacation--start, &.vacation--end {
							--bg-opacity: 0.45;
						}
					}
				}

				
			}

			&.weekend {
				color: rgba(#373735, 0.5);
			}

			&.past {
				color: rgba(#373735, 0.15);
				cursor: not-allowed;
			}

			&.today {
				background-color: rgba(0, 0, 0, 0.8) !important;
				color: white;
				border-radius: 0.6rem;
			}

			&.closed {
				position: relative;

				&:after {
					content: '';
					position: absolute;
					top: 0.4rem;
					left: 0.1rem;

					border-left: 0.5rem solid transparent;
					border-right: 0.5rem solid transparent;
					border-bottom: 0.5rem solid $color-gold-3;

					transform: rotate(-45deg);
				}
			}
		}
	}

	.ranges {
		display: flex;
		flex-direction: column;
		width: 100%;
		max-width: 100rem;

		.range {
			display: flex;
			align-items: center;
			margin-bottom: 1rem;

			.dates {
				display: flex;
				align-items: center;
				font-size: 1.6rem;
				font-family: $font-heading;
				background-color: rgba(0, 0, 0, 0.1);
				padding: 0.6rem 1.2rem;
				border-radius: 0.6rem;
				margin-right: 1rem;

				svg {
					margin: 0 2rem;
				}

				span {
					
				}
			}
		}
	}

	.actions {
		margin-top: 2rem;
		display: flex;
		width: 100%;
		max-width: 100rem;
	}
}

.vacation__modal {
	padding: 1rem;
	font-size: 1.8rem;
	font-family: $font-heading;

	.text {
		padding: 0.5rem 1rem;
	}

	.actions {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin: 2rem 0 0 0;
		font-size: 1.4rem;
		font-weight: 500;

		.Button:not(:last-of-type) {
			margin-right: 1rem;
		}
	}
}
