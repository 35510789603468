@import './../../variables.scss';

.Disclaimer {
	display: flex;
	justify-content: center;

	.disclaimer__text {
		width: 100%;
		padding: 2rem 2rem;
		max-width: 80rem;
		font-family: $font-main;
		font-size: 1.4rem;

		h2 {
			line-height: 1;
			font-size: 2.8rem;
			margin: 0 0 1.6rem 0;
			font-family: $font-main;
			font-weight: 800;

			&:after {
				content: none;
			}
		}

		h3 {
			font-weight: 700;
			font-size: 1.4rem;
		}

		h4 {
			font-size: 1.4rem;
			text-decoration: underline;
			font-weight: 400;
			margin-bottom: 0;
		}

		p {
			margin: 0 0 1.4rem 0;
		}

		ul {
			li {
				ul {
					li {

					}
				}
			}
		}

		ol {
			li {
				margin: 0 0 1.4rem 0;
			}

			&.main__list {
				padding: 0;

				li {
					&::marker {
						font-size: 1.4rem;
						font-weight: 700;
					}
				}
			}
		}

		.bold {
			font-weight: 600;
		}

		.address {
			display: flex;
			flex-direction: column;
		}
	}
}
