@import './../../../variables';

.ReviewOrderStep {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1rem;

    .submit__success {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex-direction: column;

        .checkmark {
            border-right: 1rem solid $color-green-1;
            border-bottom: 1rem solid $color-green-1;
            height: 10rem;
            width: 5rem;
            transform: rotate(40deg);
            margin: 5rem 0;
        }

        .success__message {
            font-family: 'Oswald';
            font-size: 3.2rem;
            text-transform: uppercase;
            text-align: center;
        }

        .success__actions {
            margin: 2rem 0;

            .success__action {
                padding: 1rem 2.5rem;
                background-color: $color-black-2;
                color: white;
                font-family: $font-heading;
                text-transform: uppercase;
                font-size: 1.6rem;
                border-radius: $radius-default;
                cursor: pointer;
            }
        }
    }

    .moduleReminder {
        font-family: $font-main;
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 1.4;
        padding: 2.5rem;
        margin: 0 0 1rem 0;
        max-width: 80rem;
        width: 100%;
        border-radius: $radius-default;
        background-color: $color-black-2;
        color: white;
    }

    .info {
        font-family: $font-main;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.4;
        margin: 0 0 1rem 0;
        max-width: 80rem;
    }

    .review {
        max-width: 120rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            display: flex;
            align-items: center;
            gap: 2.4rem;

            .Button {
                font-size: 1.4rem;

                .content {}
            }
        }

        h3,
        .detail__title {
            margin: 0;
            font-family: $font-heading;
            text-transform: uppercase;
            font-weight: 400;
            font-size: 2.4rem;
            color: $color-gold-2;
        }

        .review__details,
        .review__delivery,
        .review__lines {
            display: flex;
            flex-direction: column;
            max-width: 80rem;
            width: 100%;
            padding: 2rem 4rem;
            margin: 1rem;
            border: 1px solid $color-grey-0;
            background-color: white;
            border-radius: $radius-default;

            .detail__item {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin: 2rem 0;

                .detail__content {
                    font-size: 1.4rem;
                    margin: 0.5rem 0 0 0;
                    font-weight: 400;

                    p {
                        margin: 0;
                    }
                }
            }
        }

        .review__details {

            .detail__item--reference,
            .detail__item--comment {
                .detail__content {
                    font-style: italic;
                }
            }
        }

        .review__delivery {
            flex-direction: row;
            font-family: $font-heading;

            .detail__content {
                p {
                    font-size: 1.6rem;
                }

                &.detail__content--delivery-date {
                    font-weight: 500;
                    font-size: 2.4rem;
                }

                .address__name {
                    font-weight: 400;
                }

                .address__street,
                .address__city {
                    text-transform: uppercase;
                    font-weight: 300;
                }
            }
        }

        .review__lines {
            h3 {
                margin: 2rem 0;
            }

            .disclaimer {
                font-size: 1.2rem;
                color: $color-grey-2;
                display: flex;
                flex-direction: row;
                gap: 0.6rem;
            }

            .lines__total {
                display: flex;
                flex-direction: row;
                border-top: 1px solid $color-grey-0;
                padding: 1.5rem 0 1.5rem 0;
                font-family: $font-heading;
                color: $color-black-2;
                font-size: 2.2rem;

                .total__total {
                    color: $color-main-light-0;
                    text-transform: uppercase;
                    min-width: 9.5rem;
                }

                .total__products {
                    margin: 0;
                    font-weight: 400;
                    width: 100%;
                }

                .saving_points {
                    margin-left: 8px;
                }

                .total__quantity {
                    font-size: 2rem;
                    text-transform: uppercase;
                    text-align: right;
                    width: 100%;

                    span {
                        color: rgba($color-black-2, 0.6);
                        margin: 0 0.4rem;
                    }

                    .quantity__in-order {
                        color: $color-black-2;
                    }

                    .quantity__free {
                        color: $color-red-0;

                        span {
                            color: $color-red-0;
                            margin: 0 0 0 1rem;
                        }
                    }

                    .quantity__total {
                        color: $color-black-2;
                    }

                }
            }
        }
    }

    .review__warnings,
    .save__notifications {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        max-width: 80rem;
        margin: 1rem 0 0 0;

        .review__warning,
        .save__notification {
            color: $color-black-2;
            width: 100%;
            padding: 1rem 2.5rem;
            font-family: $font-heading;
            font-size: 2rem;
            display: flex;
            align-items: center;

            svg {
                color: $color-orange-1;
                margin: 0 1rem 0 0;
            }
        }

        .save__notification {
            svg {
                color: $color-red-0;
            }
        }
    }

    .review__error {
        background-color: $color-red-0;
        color: white;
        font-size: 2rem;
        width: 100%;
        max-width: 80rem;
        border-radius: $radius-default;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem;
        font-family: $font-heading;
        margin: 1rem 0 0 0;
    }

    .complete__button {
        max-width: 80rem;
        width: 100%;
        height: 6rem;
        background-color: $color-gold-3;
        box-shadow: $box-shadow-1;
        margin: 1rem 0 2rem 0;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-family: $font-heading;
        color: $color-black-2;
        font-size: 2.4rem;
        transition: $cubic-bezier-1 0.3s all;
        cursor: pointer;
        border-radius: 3rem;

        &:hover {
            background-color: darken($color-gold-3, 3);
            box-shadow: $box-shadow-2;
        }

        &.complete__button--disabled {
            background-color: $color-grey-0;
            box-shadow: none;
            color: $color-text-grey-0;
            cursor: default;

            &:hover {
                box-shadow: none;
            }
        }

        &.complete__button--errors {
            background-color: $color-red-0;
            box-shadow: none;
            color: white;
            cursor: default;

            &:hover {
                box-shadow: none;
            }
        }
    }

    .confirmations {
        color: red;
        max-width: 80rem;
        border: 1px solid #ececec;
        background-color: white;
        border-radius: 2.5rem;

        &.confirmation__warning {
            border: 3px solid $color-red-0;
            border-radius: 2.8rem;
        }

        .confirmation__banner {
            width: 100%;
            border-radius: 2.5rem 2.5rem 0 0;
        }

        .confirmation__banner--description {
            text-align: center;
            color: white;
            background-color: $color-red-0;
            margin-top: -2px;
            padding: 5px;
            font-size: 1.5rem;
        }

        label {
            color: darkred;
            font-size: 13px;
        }

        .confirmation__title {
            color: $color-red-0;
            text-transform: uppercase;
            font-family: $font-heading;
            font-weight: 400;
            font-size: 2rem;
            color: $color-black-2;
            margin: 0 0 1rem 0;
            padding: 1rem 0 0 0;
            display: flex;
            justify-content: space-between;

            .confirmation__name {
                color: $color-red-0;
                display: flex;
                align-items: center;
                margin-left: 2rem;

                .confirmation__clear {
                    cursor: pointer;
                    margin: 0 0 0 1rem;
                }

                .confirmation__name--img {
                    width: 6rem;
                    height: 6rem;
                    margin-right: 1rem;
                }
            }
        }

        .confirmation__items {
            display: flex;
            margin: 0 0 2rem 2rem;

            .Spinner {
                margin: 2rem 0;
            }
        }

        .items__group {
            display: flex;
            margin: 0.4rem 0;
        }

        .confirmation__item {
            display: flex;
            align-items: center;
            margin: 0 0 0.4rem 0;
            font-size: 1.3rem;
            cursor: pointer;
            color: $color-black-2;
            font-family: $font-main;
            user-select: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            width: auto;
            padding-right: 2rem;

            .Spinner {
                width: 2rem;
                margin: 0 1rem 0 0;
            }

            span.questionLabel {
                font-size: 1.4rem;

                &.highlight {
                    font-size: 1.8rem;
                    font-weight: bold;
                }
            }
        }

        &.confirmation--collapse {
            .confirmation__title {
                margin: 0;
            }

            .confirmation__items {
                display: none;
            }
        }
    }

    .confirmation__item {

        &.confirmation--off,
        &.confirmation--false {
            color: $color-grey-2;
        }

        &.confirmation--include,
        &.confirmation--true {
            font-weight: 600;
        }
    }

    .eob-hint {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: 80rem;
        width: 100%;
        padding: 2rem 2rem;
        margin: 1rem;
        border: 1px solid $color-grey-0;
        background-color: white;
        border-radius: $radius-default;

        .title-badge {
            background-color: $color-eob;
            border-radius: 2.5rem;
            color: white;
            font-family: $font-heading;
            padding: 0.5rem 1.5rem;
            text-transform: uppercase;
            margin-bottom: 1rem;
        }
    }
}

@media (max-width: 650px) {
    .ReviewOrderStep {
        .review {
            .review__delivery {
                flex-direction: column;
            }

            .review__lines {

                .lines__total {
                    flex-direction: column;

                    .total__quantity {
                        text-align: left;
                    }
                }
            }
        }

        .complete__button {
            font-size: 2rem;
        }
    }
}