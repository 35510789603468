@use '../../variables' as *;

.c-alert {
	.c-alert__body {
		padding: 0 1rem;
		color: $color-black-2;
		font-size: 1.4rem;
		font-family: $font-heading;
		font-weight: 300;
		max-width: 270px;
	}

	.c-alert__progress {
		background: $color-main-light-0;
	}

	.Toastify__close-button {
		color: $color-black-2;
	}

	&.Toastify__toast--success {
		background-color: white;

		.c-alert__progress {
			background: $color-green-1;
		}
	}

	&.Toastify__toast--error {
		background-color: white;
		border-left: 1rem solid $color-red-0;
	}
}