@import './../../variables.scss';

.Margins {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 2rem;
	font-family: $font-heading;
	font-weight: 300;
	min-height: 100vh;

	input[type=number]{
		min-width: unset;
		border: 1px solid #ddd;
		border-radius: 1rem;
		padding: .4rem 1rem;
		font-family: $font-heading;
		font-weight: 300;
		font-size: 1.4rem;
		max-width: 8rem;
		-moz-appearance: textfield;
		margin-right: .5rem;
		margin-left: .5rem;
		text-align: right;
		&.edited{
			border: 2px solid #989898;
		}
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	.List{
		min-width: 90rem;
		.list__wrapper .list__body .list__item{
			.item__cell{
				white-space: nowrap;
			}
		}
	}

	.categoryArticles{
		.List{
			.list__wrapper .list__body .list__item{
				padding: .5rem 2.5rem .5rem .8rem;
			}
		}
	}
	img{
		display: block;
		height: 4rem;
		border-radius: 1rem;
		aspect-ratio: 1;
		object-fit: cover;

	}
	.proposed{
		&.overwritten{
			color: red;
			opacity: .3;
		}
	}

	.Button {
		+ .Button {
			margin-left: 1rem;
		}
	}
	.ProductNavigator {
		position: absolute;
		display: flex;
		justify-content: space-between;
		margin: 2rem 0 0 0;
		padding: 0 1rem 0 2rem;
		width: 100%;

		.Button {
			.content {
				display: flex;
				align-items: center;
				justify-content: center;

				.arrow {
					&.arrow-left{
						margin-right: 1rem;
					}
					&.arrow-right{
						margin-left: 1rem;
						transform: scaleX(-1);
					}
				}
			}
		}
	}

	.Overview{
		.productType{
			border: 1px solid #ddd;
			padding: 1rem;
			margin: 1.5rem 0 0;
			border-radius: 2rem;

			.List{
				.list__wrapper .list__body .list__item{
					padding: .5rem 2.5rem .5rem 2.5rem;
				}
			}
			&:last-of-type{
				margin-bottom: 2rem;
			}
		}

		h3{
			font-size: 3rem;
			font-weight: 500;
			margin: 4rem 2rem 1rem;
		}
		h4{
			font-size: 2.2rem;
			 margin: 1rem;
			 font-weight: 400;
		 }
	}

	.Detail{
		min-height: 100vh;
		.categoryArticles{
			min-height: 100vh;
		}
		h3, h4 {
			font-family: $font-heading;
			text-transform: uppercase;
			span + span {
				margin-left: 1rem;
			}
		}

		h3{
			margin-bottom: 0;
			margin-top: 2rem;
		}

		h4{
			margin-top: 0;
			font-weight: 400;
		}
		min-width: 90rem;
		.List{
			margin-bottom: 1rem;
		}
	}

	.actions{
		position: sticky;
		bottom: 0;
		display: flex;
		justify-content: center;
		background: white;
		padding: 2rem;
		box-shadow: 0 -8px 8px -8px rgba(0,0,0,.2);
	}

	.shake{
		animation: .8s shake;
	}
}

@keyframes shake {
	0% {
		transform: translateX(0);
	}
	20% {
		transform: translateX(-11px);
	}
	30% {
		transform: translateX(11px);
	}
	40% {
		transform: translateX(-8px);
	}
	50% {
		transform: translateX(8px);
	}
	60% {
		transform: translateX(-5px);
	}
	70% {
		transform: translateX(3px);
	}
	80% {
		transform: translateX(0);
	}
}
