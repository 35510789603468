@import './../../../variables';

.ProductsList {
	height: 100%;

	&.view--list {
		display: flex;
		flex-direction: column;
		width: 100%;

		.list-item__loading {
			padding: 1rem;
			margin: 0.5rem 0;
			border: 1px solid $color-grey-0;
			border-radius: $radius-default;
			height: 8.4rem;
			display: flex;
			justify-content: space-between;

			.breathing {
				border-radius: $radius-default;
				animation-name: loading-breathing;
				animation-duration: 2s;
				animation-iteration-count: infinite;
				animation-timing-function: ease-in-out;
				animation-direction: alternate;
			}

			.loading__image {
				min-width: 6.2rem;
				height: 6.2rem;
				border-radius: 1rem;
			}

			.container__id-desc {
				display: flex;
				flex-direction: column;
				width: 100%;
				margin: 0 0 0 1rem;
			}

			.loading__id {
				width: 4rem;
				height: 1.4rem;
			}

			.loading__desc {
				width: 30rem;
				height: 3rem;
				margin: 0.5rem 0 0 0;
			}

			.loading__history {
				height: 6.1rem;
				min-width: 30rem;
				border-radius: $radius-history;
			}

			.loading__quantity-input {
				min-width: 16rem;
				height: 4.8rem;
				margin: 1rem 0 0 1rem;
			}
		}
	}

	&.view--list-compact {
		display: flex;
		flex-direction: column;
		width: 100%;

		.list-item__loading {
			height: 4.8rem;
			padding: 0.5rem 0.5rem 0.5rem 1.5rem;

			.loading__image {
				display: none;
			}

			.container__id-desc {
				display: flex;
				flex-direction: row;
				align-items: center;
				margin: 0 0.5rem;
			}

			.loading__id {
				margin: 0 0.5rem 0 0;
				width: 2.8rem;
			}

			.loading__desc {
				width: 30rem;
				height: 3rem;
				margin: 0 0.5rem 0 0;
			}

			.loading__history {
				height: 3.6rem;
				min-width: 25rem;
			}

			.loading__quantity-input {
				height: 3.6rem;
				min-width: 13rem;
				margin: 0 0 0 1rem;
			}
		}
	}

	&.view--grid {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: 1rem;
		grid-row-gap: 1rem;
		width: 100%;

		.list-item__loading {
			display: flex;
			flex-direction: column;
			border: 1px solid $color-grey-0;
			padding: 1.5rem;
			border-radius: $radius-default;

			.breathing {
				border-radius: $radius-default;
				animation-name: loading-breathing;
				animation-duration: 2s;
				animation-iteration-count: infinite;
				animation-timing-function: ease-in-out;
				animation-direction: alternate;
			}

			.loading__image {
				height: 19rem;
				width: 100%;
			}

			.loading__id {
				width: 4rem;
				height: 1.2rem;
				margin: 0.5rem 0 0 0.5rem;
			}

			.loading__desc {
				width: 75%;
				height: 2.6rem;
				margin: 0.5rem 0 0 0;
			}

			.loading__history {
				width: 100%;
				height: 6.1rem;
				margin: 1rem 0 0 0;
				border-radius: $radius-history;
			}

			.loading__quantity-input {
				width: 16rem;
				height: 4.8rem;
				margin: 1rem 0 0 0;
			}

		}
	}

	.list__row {
		display: flex;
		width: 100%;

		.Article {
			background-color: white;
			width: 100%;

			&+.Article {
				margin: 0 0 0 5px
			}

			--detail-column-width: auto;
		}
	}
}
