@import './../../variables.scss';

.Documents {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	.documents__tabs {
		display: flex;
		width: 100rem;
		display: flex;
		justify-content: space-around;
		padding: 4rem 0 2rem 0;
		margin: 0;
		list-style: none;
		position: fixed;
		top: 0;
		background-color: white;
		z-index: 5;

		.tabs__item {
			font-family: $font-heading;
			text-transform: uppercase;
			font-size: 3.6rem;
			font-weight: 600;
			display: flex;
			flex-direction: column;

			.item__link {
				text-decoration: none;

				&::after {
					content: '';
					width: calc(100% + 4rem);
					margin-left: -2rem;
					height: 0.5rem;
					background-color: transparent;
					display: block;
					transform: rotate(-1deg);
				}

				&:hover {
					&::after {
						background-color: rgba($color-grey-1, 0.5);
					}
				}

				&.item__link--active {
					&::after {
						background-color: $color-gold-3;
					}

					&.item__link--unauthorized {
						color: rgba($color-black-2, 0.3);
						cursor: not-allowed;

						&::after {
							background-color: rgba($color-gold-3, 0.2);
						}
					}
				}
			}
		}
	}

	&> div {
		margin-top: 11rem;
	}
}
