// Sections
.BuylistArticle {

	.ArticleImage,
	.Article__name {
		cursor: pointer;
	}
}

.detail {
	gap: .4rem .8rem;
	display: grid;

	.top {
		grid-area: top;
		display: flex;
		gap: .3rem .7rem;
		max-width: 100%;
		flex-wrap: wrap;
		max-height: 2.4rem;
		line-height: 2.4rem;
		overflow: hidden;
	}

	.middle {
		grid-area: buylistName;
		display: flex;
		gap: .3rem .7rem;
		max-width: 100%;
	}
}

.order {
	grid-area: order;
	display: flex;
	flex-direction: column;
	justify-items: flex-end;
	align-content: space-between;
	gap: .8rem;
	background: white;
}

.bottom {
	grid-area: bottom;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	line-height: 2.4rem;
	gap: var(--grid-gap);

	>div {
		display: flex;
		flex-direction: row;
		gap: .5rem;
	}
}


.list {
	// TODO: reserved falls out of the bottom
	--article-middle-height: 11.6rem;
	--article-bottom-height: 3rem;
	--grid-gap: 1rem;

	--detail-column-width: 47rem;
	--order-column-width: 25rem;


	grid-template-areas:
		'image datails order'
		'image bottom bottom';

	.ArticleImage {
		aspect-ratio: 1 / 1;
	}

	.detail {
		grid-template-areas:
			'top'
			'buylistName'
			'allergens'
			'history';
		grid-template-columns: minmax(0, 1fr);
		min-width: 0; // this makes the area able to shrink past content width
		grid-template-rows: 2.4rem 2rem 2rem 4rem;
	}

	.order {
		justify-self: end;
		display: grid;
		grid-template-areas:
			'quantity'
			'inOrder';
		justify-items: end;
		align-items: start;
		gap: .8rem;
	}

	&.size935 {
		--detail-column-width: 40rem;
		--order-column-width: 16rem;

		.Article__name,
		.promo__description {
			font-size: 1.35rem;
		}

		--article-middle-height: 10.8rem;
		--article-bottom-height: 2.4rem;

		.detail {
			grid-template-rows: 2.4rem 1.6rem 1.6rem 4rem;
		}
	}

	&.size770 {
		--grid-gap: .8rem;
		--article-middle-height: 8.8rem;
		--article-bottom-height: 2.5rem;

		.detail {
			grid-template-areas:
				'top top'
				'buylistName buylistName'
				'history allergens';
			grid-template-columns: 1fr auto;
			grid-template-rows: 2.4rem 1.6rem 4rem;

			.Allergens {
				--Allergen-size: 1.8rem;
				--Allergen-gap: .4rem;
			}
		}

		--detail-column-width: auto;

	}


	--article-height: calc(var(--article-middle-height) + var(--article-bottom-height) + var(--grid-gap) + var(--article-padding) * 2 + 2px);
	--image-column-width: calc(var(--article-height) - var(--article-padding));

	height: var(--article-height);
	gap: var(--grid-gap);
	grid-template-columns: var(--image-column-width) minmax(var(--detail-column-width), auto) minmax(var(--order-column-width), auto);
	grid-template-rows: var(--article-middle-height) var(--article-bottom-height);

}

.list-compact {
	--article-middle-height: 4rem;
	--article-bottom-height: 2.4rem;
	--grid-gap: .4rem;

	--detail-column-width: 47rem;
	--order-column-width: auto;


	grid-template-areas:
		'details order'
		'bottom bottom';


	.detail {
		grid-template-areas:
			'top allergens'
			'buylistName buylistName';
		min-width: 0; // this makes the area able to shrink past content width
		gap: .3rem .8rem;
		grid-template-rows: 1.7rem 2rem;
		grid-template-columns: max-content auto;

		.Allergens {
			--Allergen-size: 1.8rem;
			--Allergen-gap: .4rem;
		}

		.top {
			grid-area: top;
			display: flex;
			gap: .3rem .7rem;
			max-width: 100%;
			flex-wrap: wrap;
			max-height: 1.7rem;
			line-height: 1.7rem;
			font-size: 1.2rem;

			.Badge {
				font-size: 1.1rem;
				--padding-v: .2rem;
			}

			overflow: hidden;
		}
	}

	.order {
		display: grid;
		grid-template-areas: 'inOrder history quantity';
		align-items: center;
		justify-self: end;
		gap: .8rem;
		min-width: 16rem;

		.Article__in-order-indicator {

			//--padding-v: 1.1rem;
			//--padding-h: 1.1rem;
			.Badge__primary {
				border-radius: 1.2rem;
			}
		}
	}

	&.size935 {

		.Article__name,
		.promo__description {
			//font-size: 1.35rem;
		}

		//--article-middle-height: 4rem;
		.detail {
			grid-template-rows: 1.7rem 2rem;
		}
	}

	&.size770 {
		--detail-column-width: auto;
	}

	--article-height: calc(var(--article-middle-height) + var(--article-bottom-height) + var(--grid-gap) + var(--article-padding) * 2 + 2px);
	grid-template-rows: var(--article-middle-height) var(--article-bottom-height);

	&.without-bottom {
		grid-template-areas: 'details order';
		--article-height: calc(var(--article-middle-height) + var(--article-padding) * 2 + 2px);
		grid-template-rows: var(--article-middle-height);

		.bottom {
			display: none;
		}
	}


	height: var(--article-height);
	gap: var(--grid-gap);
	grid-template-columns: minmax(var(--detail-column-width), auto) minmax(var(--order-column-width), auto);

}

.list,
.list-compact {
	.Article__image {
		margin-right: 0;
	}
}

.grid {
	--min-width: 30rem;
	height: calc(54rem + var(--article-padding) * 2);
	min-width: var(--min-width);
	flex: 1 0 var(--min-width);
	--grid-gap: 1rem;
	gap: var(--grid-gap);
	grid-template:
		'image' 20rem
		'details' min-content
		'bottom' min-content
		'order' auto;

	.detail {
		grid-template:
			'top' 2.4rem
			'buylistName' min-content
			'history' 6.1rem
			'allergens' 2.4rem;

		.middle {
			.Article__name {
				white-space: unset;
				text-overflow: unset;
				//overflow: unset;
			}
		}

		.Allergens {
			min-width: unset;
		}
	}

	.order {
		justify-content: space-between;
		flex-direction: row;
		flex-wrap: wrap-reverse;

	}

	.bottom>div {
		flex-direction: column;
	}

	.Article__image {
		margin-bottom: 0;
		min-height: 0;
	}

	.Article__in-order-indicator {
		grid-area: 1 / 1 / 2 / 2;
		justify-self: end;
	}
}