@import './../../variables.scss';

.Reports {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2rem;

	.no-reports {
		font-size: 2rem;
		font-family: $font-heading;
		text-transform: uppercase;
	}

	.Search {
		padding-bottom: 1rem;
	}

	.List, .Pillbox {
		max-width: 125rem;
		width: 100%;
	}

	.filter__area {
		margin-bottom: 1.5rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
	}

	.report__name, .report__description {
		margin: 0;
		font-weight: 400;

		.highlighted {
			font-weight: 300;
		}
	}

	.report__description {
		color: $color-text-grey-2;
	}

	.item__cell.download, .header__column.download {
		text-align: right;
	}

	.item__cell.download {
		color: $color-pdf;
		font-size: 1.6rem;
	}

	.view__details {
		display: grid;
		grid-template-columns: 1fr 1fr;
		font-size: 1.6rem;
		font-family: $font-heading;
		font-weight: 300;
		padding-left: 2.5rem;
		margin-bottom: 2rem;
		width: 100%;
		max-width: 125rem;
	}

}
