@import './../../../../variables.scss';

.ProductItem .product__tiny-item {
	background-color: white;
	width: 100%;
	display: flex;
	flex-direction: column;
	border: 1px solid $color-grey-0;
	border-radius: $radius-default;
	margin: 0 0 0.5rem 0;
	font-family: $font-heading;
	padding: 0.5rem 0.5rem 0.5rem 2rem;
	overflow: hidden;
	position: relative;

	.product__id {
		font-size: 1.2rem;
		color: $color-text-grey-0;
	}

	.product__brand {
		font-size: 1.2rem;
		margin: 0 0 0 1rem;
		color: rgba($color-black-2, 0.9);
	}

	.product__top {
		display: flex;
		justify-content: space-between;
		height: 3.6rem;

		.product__body {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			z-index: 1;

			.body__top {
				display: flex;
				align-items: center;
				font-size: 1.2rem;
				margin: -0.1rem 0 0.1rem 0;
				background-color: white;
			}

			.body__bottom {
				display: flex;
				align-items: center;
			}
		}
	}
	.product__name {
		font-size: 1.6rem;
		line-height: 1;
		font-weight: 400;
		word-wrap: break-word;
		white-space: nowrap;
		margin-right: 1rem;
		background-color: white;
	}
}
