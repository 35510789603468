@import './../../../../variables';

.reminder--charles_prepackaged-new_ean {
	text-align: center;
	font-family: $font-heading;
	color: black;
	padding: 1rem 2rem;
	font-weight: lighter;
	position: relative;

	h1 {
		color: $color-gold-0;
		margin-top: 0;
	}

	em {
		font-weight: bolder;
		font-style: initial;
	}

	.reminder__description {
		margin-bottom: 2rem;
	}

	img {
		position: absolute;
		height: 130px;
		transform: rotate(-20deg);
		left: -75px;
		top: -50px;
		background-color: white;
	}
}
