@import './../../../variables.scss';

.MainFilters {
	.filter__item {

		&.filter--exclude {
			color: $color-grey-2;
			text-decoration: line-through;
		}

		svg {
			margin: 0 0 0 0.5rem;
		}

		&.filter__reserved {
			svg {
				color: $color-eob;
			}
		}

		&.filter__campaign {
			span {
				color: white;
				font-size: 1.1rem;
				width: auto;
				height: 2rem;
				padding: 0.5rem 0.8rem;
				display: flex;
				align-items: center;
				justify-content: center;
				text-transform: uppercase;
				border-radius: $radius-default;
				background: rgb(201, 107, 184);
				font-family: $font-heading;
				font-weight: 400;
				margin: 0 0 0 0.5rem;
				overflow: hidden;
				white-space: nowrap;
				border: 1px solid transparent;
			}
		}
	}
}