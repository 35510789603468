@import './../../variables';

.ProductPicker {
	width: 100%;
	max-width: 81rem;

	&.important {
		border-radius: $radius-default;
		border: 1px solid #d44e4e;
	}

	&:not(.important) .sample:not(.selected),
	&:not(.important) .noSample:not(.selected) {
		opacity: .5;
	}

	h3 {
		font-size: 2.4rem;
		text-transform: uppercase;
		margin: 2rem 0 0;
		text-align: center;
		font-family: 'Oswald', sans-serif;
		font-weight: normal;
	}

	p {
		padding: 0 2rem;
		font-size: 1.3rem;
	}

	.productList {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;


		.sample,
		.noSample {
			flex-grow: 1;
			flex-shrink: 1;
			flex-basis: 38rem;
			margin: 1rem;
			position: relative;
			border-radius: $radius-default;
			background-color: #fafafa;
			border: 1px solid rgba(0, 0, 0, .2);
			display: inline-flex;
			flex-direction: column;

			.product {
				background-color: white;
				overflow: hidden;
				border: 1px solid #d9d8d8;
				border-radius: $radius-default;
				width: 100%;
				display: flex;
				flex-direction: row;
				padding: 0;
				cursor: pointer;
				height: 8rem;
				margin: -1px;
				box-sizing: content-box;

				img {
					height: 8rem;
					width: 8rem;
					border-radius: $radius-default;
					margin-right: 1rem;
					box-shadow: 0 0 8px rgba(0, 0, 0, .2);
					transition: .2s;

					&:hover {
						transform: scale(1.05);
					}
				}

				span {
					line-height: 8rem;
					font-family: 'Oswald', sans-serif;
					font-size: 2rem;
					margin: 0 0 0 .5rem;
					text-transform: uppercase;
					color: #222222;

					&.id {
						color: #888888;
					}

					&.none {
						text-align: center;
						width: 100%;
					}
				}
			}

			&.disabled .product {
				cursor: default;

				img {
					&:hover {
						transform: none;
					}
				}
			}

			.sampleAction {
				position: absolute;
				top: -1rem;
				right: -1rem;
				height: 3rem;
				width: 3rem;
				padding: 0;
				color: #0d181c;
				border: 1px solid #cacaca;
				border-radius: 1.5rem;
				background: white;
				cursor: pointer;
				display: grid;
				place-items: center;

				&.unselect {
					background-color: #c1ad76;
					color: white;
					border: 1px solid transparent;

					& svg:last-child {
						display: none;
					}

					&:hover {
						background-color: #d44e4e;

						& svg:last-child {
							display: block;
						}

						& svg:first-child {
							display: none;
						}
					}
				}

				&.select {
					color: #c1ad76;
				}

				&.selected {
					background-color: #c1ad76;
					color: white;
					border: 1px solid transparent;
				}
			}

			p {
				font-size: 1.4rem;
				padding: 0 1rem 2rem;
				display: inline-block;
			}

			.more {
				display: block;
				position: absolute;
				bottom: 1.7rem;
				right: 2.2rem;
				font-size: 1.4rem;
				text-decoration: underline;
				color: gray;
				cursor: pointer;
			}

			&.selected button {
				border: 1px solid #bebebe;
			}
		}
	}
}