@import './../../variables.scss';

.Settings {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	.container {
		max-width: 100rem;
		padding: 2.4rem 1.2rem;
		display: flex;
		flex-direction: column;
		width: 100%;

		.tabs {
			display: flex;
			width: 100%;
			justify-content: flex-start;
			gap: 1.2rem;
			border-bottom: 1px solid rgb(224, 224, 224);
			padding: 0 0 1.2rem 0;
			margin-bottom: 1.2rem;

			.tabs__item {
				--color-r: 51;
				--color-g: 51;
				--color-b: 51;
				--color: rgb(var(--color-r), var(--color-g), var(--color-b));
				background-color: rgba(var(--color-r), var(--color-b), var(--color-g), 0.1);
				border-color: transparent;
				border-radius: 1.2rem;
				padding: 0.8rem 2rem;
				font-family: "Oswald", sans-serif;
				text-transform: uppercase;
				font-size: 1.6rem;
				cursor: pointer;
				transition: 0.2s ease all;
				position: relative;
				margin: 0;
				display: inline-block;

				a {
					text-decoration: none;
				}
			}

			.Button {
				white-space: nowrap;

				&:not(:hover) {
					background: none;
				}

				&.active,
				&:hover {
					background-color: rgba(var(--color-r), var(--color-b), var(--color-g), 0.1);
				}
			}
		}
	}

	.Contact {
		display: flex;
		flex-direction: row;
		gap: 1.2rem;
		align-items: flex-start;

		.menu {
			min-width: 20rem;
			width: 20rem;
			display: flex;
			flex-direction: column;
			gap: 0.6rem;
			position: sticky;
			top: 1.2rem;

			.menu-item {
				font-family: $font-heading;
				width: 100%;
				box-shadow: none;
				background: none;
				color: $color-black-2;
				font-size: 1.5rem;

				&:hover {
					background-color: rgb(245, 245, 245);
				}
			}
		}

		.cards {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 1.2rem;

			p {
				font-size: 1.4rem;
				font-family: "Open Sans", sans-serif;
				margin: 2rem;
			}

			.card {
				background-color: rgb(245, 245, 245);
				//border: 1px solid rgb(224, 224, 224);
				border-radius: $radius-default;
				padding: 2rem;



				h2 {
					font-size: 2.2rem;
					font-weight: 500;
					margin: 0 0 1.2rem 0;

					&::after {
						content: none;
					}
				}

				.DynamicForm .form__field {
					--form-field-padding: 0.6rem 0;
				}
			}
		}
	}

	.Communication {
		display: flex;
		flex-direction: column;
		gap: 1.2rem;

		p {
			font-size: 1.4rem;
			font-family: "Open Sans", sans-serif;
			margin: 2rem;
		}

		h1 {
			font-family: $font-heading;
			text-transform: uppercase;
			font-size: 2rem;
			font-weight: 500;
		}

		tr:nth-child(even) {
			background-color: rgba(236, 236, 236, .2);
			border: 1px solid #ececec;

		}

		td {
			font-size: 1.4rem;
			padding: 2rem;
			font-family: "Open Sans", sans-serif;
			color: #6c757d;
		}

		table {
			border-collapse: collapse;
			border-radius: $radius-default;
			border-style: hidden;
			box-shadow: 0 0 0 1px #ececec;
			width: 100%;
		}

		tbody {
			border: 1px solid #ececec;
		}
	}

	.SaveAndChanges {
		margin-top: 2rem;
		display: flex;
		flex-direction: row;
		align-items: center;

		.green {
			p {
				color: green;
				margin-left: 3rem;
				font-size: 1.2rem;
			}
		}

		p {
			color: red;
			margin-left: 3rem;
			font-size: 1.2rem;
		}
	}
}

.hints {
	font-size: 1.2rem;
	color: orange;
}