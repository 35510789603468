@import './../../../variables.scss';

.ForbiddenError {
	display: flex;
	flex-direction: row;
	margin: 2rem 0;

	.error__image {
		max-height: 16rem;

		img {
			max-height: 16rem;
		}
	}

	.error__content {
		display: flex;
		flex-direction: column;
		justify-content: center;

		margin: 0 0 2rem 0;
		padding: 0 2rem;

		font-family: $font-heading;
		font-size: 2rem;

		.error__title {
			font-size: 2.8rem;
		}
	}
}