.Disclaimer-holder {
    .Disclaimer {
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding-left: 0.5rem;
        color: rgba(0, 0, 0, 0.5);
    }

    .modal__body {
        height: auto;
        border-radius: 0.5rem;
        margin: 0.5rem;

        p {
            font-size: 1.7rem;
            line-height: 1.5;
            max-width: 40rem;
            padding: 1rem;
            margin-top: 2.5rem;
        }
    }
}
