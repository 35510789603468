@import './../../variables.scss';

.OGCheckbox {
	margin: 0 1rem 0 0;
	font-family: $font-main;
	font-weight: 600;

	&.radio {
		input {
			display: none;
		}

		label::before {
			content: none;
		}

		label::after {
			content: none;
		}
	}

	input {
		opacity: 0;
	}

	input+label {
		color: rgba($color-black-2, 0.8);
	}

	input:checked+label {
		color: rgba($color-black-2, 1);
	}

	input[disabled=""]+label {
		color: rgba($color-black-2, 0.5);
		cursor: default;
	}

	input[disabled=""]:checked+label {
		color: rgba($color-black-2, 0.75);
	}

	input:checked+label::before,
	&.include label::before {
		background-color: $color-green-1;
		border-color: $color-green-1;
	}

	input[disabled=""]+label::before {
		content: none;
	}

	input+label::after {
		content: none;
	}

	input:checked+label::after,
	&.include label::after {
		content: '';
	}

	input[disabled=""]:checked+label::after {
		border-color: $color-green-1;
	}

	label {
		position: relative;
		cursor: pointer;
		margin: 0 0 0 0;

		&::before,
		&::after {
			position: absolute;
		}

		&::before {
			content: '';
			display: inline-block;
			height: 1.6rem;
			width: 1.6rem;
			border: 2px solid $color-grey-1;
			left: -2rem;
			top: -0.2rem;
			border-radius: $radius-checkbox;
		}

		&::after {
			content: '';
			display: inline-block;
			height: 0.4rem;
			width: 0.9rem;
			border-left: 0.3rem solid;
			border-bottom: 0.3rem solid;
			border-color: white;
			transform: rotate(-50deg);
			left: -1.6rem;
			top: 0.3rem;
		}
	}

	&.inverted {
		input:checked+label::before {
			background-color: $color-red-0;
			border-color: $color-red-0;
		}

		input:checked+label::after {
			content: '+';
			width: 1.6rem;
			height: 1.6rem;
			border: none;
			font-size: 2.8rem;
			left: -1rem;
			top: -0.8rem;
			transform: rotate(45deg);
			color: white;
		}
	}

	&.exclude {
		label::before {
			background-color: $color-red-0;
			border-color: $color-red-0;
		}

		label::after {
			content: '+';
			width: 1.6rem;
			height: 1.6rem;
			border: none;
			font-size: 2.8rem;
			left: -1rem;
			top: -0.8rem;
			transform: rotate(45deg);
			color: white;
		}
	}

	input:focus+label::before,
	input:hover+label::before {
		background-color: lighten($color-gold-0, 25);
		border-color: $color-gold-0;
	}

	input:checked:focus+label::before,
	input:checked:hover+label::before {
		background-color: lighten($color-gold-0, 25);
		border-color: $color-gold-0;
	}

	input:checked:focus+label::after,
	input:checked:hover+label::after {
		border-color: $color-gold-0;
	}

	input:hover+label::before {
		background-color: transparent;
	}

	input:checked:hover+label::before {
		background-color: transparent
	}

	label {
		.radio {
			width: 2rem;
			height: 2rem;
			position: relative;

			.outer {
				position: absolute;
				width: 2rem;
				height: 2rem;
				border: 2px solid $color-green-1;
				border-radius: 2rem;
			}

			.inner {
				position: absolute;
				width: 0.8rem;
				height: 0.8rem;
				border-radius: 0.8rem;
				background-color: $color-green-1;
				left: 0.6rem;
				top: 0.6rem;
			}

			&.state--true {
				.outer {}

				.inner {}
			}

			&.state--false {
				.outer {
					border-color: $color-grey-1;
				}

				.inner {
					display: none;
				}
			}
		}

		.checkbox {
			display: none;
		}
	}
}