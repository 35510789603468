@import './../../variables.scss';

.PdfModal {
	height: calc(100vh - 4rem);
	min-width: 50vw;
	//max-width: 50vw;
	background-color: grey;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 4rem;
	position: relative;

	.PdfModal__controls {
		margin: 2rem 2rem 0 2rem;
		display: flex;
		background-color: rgba(black, 0.9);
		padding: 2rem;
		position: absolute;
		bottom: 2.5rem;
		z-index: 100;
		border-radius: $radius-default;

		.controls__item {
			font-size: 2rem;
			margin: 0 1rem;
			color: white;
			cursor: pointer;
			display: flex;
			align-items: center;

			&.close {
				margin-left: 3rem;

				font-family: $font-heading;
				text-transform: uppercase;
				font-size: 1.8rem;
			}
		}

		.controls__label {
			color: white;
			font-family: $font-main;
			font-size: 1.8rem;
			font-weight: 600;
			margin: 0 1rem;
		}
	}

	.scroll-helper {
		overflow: auto;
		width: 100%;
		display: flex;
		justify-content: center;

		&.align-left {
			justify-content: flex-start;
		}
	}

	.PdfModal__pdf {
		margin: 0 1rem;
		width: auto;

		.pdf__page {
			margin: 2rem;
			width: auto;
			box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, .2);
		}
	}

	.pdf__error {
		font-size: 2rem;
		color: white;
		font-family: $font-heading;
	}

	&.loading {
		justify-content: center;

		.PdfModal__controls {
			//display: none;
		}
	}
}

@media (max-width: 480px) {
	.PdfModal {
		min-width: 100vw;
		max-width: 100vw;
		height: 100vh;
		margin: 0;
	}
}