@import './../../variables.scss';

.Order {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 100%;

    .Error.Error--medium {
        margin-top: 15rem;
    }

    .order__content {
        margin-top: 11rem;

        &.order__content--error {
            margin-top: 12rem;
        }
    }

    .order__error {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-bottom: 2rem;

        .error__message {
            background-color: $color-red-0;
            color: white;
            padding: 1.2rem 2rem;
            border-radius: $radius-default;
            font-family: $font-main;
            font-size: 1.6rem;
            box-shadow: $box-shadow-2;
            animation: slide-in-top 0.3s $cubic-bezier-1;
            margin: 0 1rem;
            line-height: 1.2;
            max-width: 130rem;
        }
    }

    .offline__info {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .info__message {
            text-transform: uppercase;
            margin: 0 0 1rem 0;
            font-family: $font-heading;
            font-size: 1.6rem;
            color: $color-red-0;
        }
    }
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
    .Order {

        .DeliveryDateSelectionStep,
        .ProductSelectionStep,
        .ReviewOrderStep,
        .FinishStep {
            margin-top: 11rem;
        }
    }
}

@media (max-width: 650px) {
    .Order {
        .order__content {
            margin-top: 7rem;

            &.order__content--error {
                margin-top: 16rem;
            }
        }

        .order__error {
            top: 14rem;
        }
    }
}

@keyframes slide-in-top {
    0% {
        margin-top: -50rem;
    }

    100% {
        margin-top: 0;
    }
}