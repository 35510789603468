@import './../../../variables';

.StepsProgress {
	padding: 1rem 0 .5rem;
	background-color: white;
	//margin: 0 0 1rem -4rem;
	box-shadow: $box-shadow-1;
	z-index: 9;
	//transition: $cubic-bezier-1 0.3s all;
	//border-bottom: 0.2rem $color-grey-0 solid;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;

	.order__id {
		text-transform: uppercase;
		font-family: $font-heading;
		font-weight: 300;
		font-size: 1.1rem;
	}

	.order__progress {
		display: flex;
		flex-direction: row;
		justify-content: center;
		counter-reset: step;
		padding: 0;

		.progress__step--li {
			font-size: 1.8rem;
			font-family: $font-heading;
			position: relative;
			color: $color-text-grey-0;
			text-transform: uppercase;
			z-index: 1;
			width: 100%;
			max-width: 20rem;
			text-align: center;
			counter-increment: step;
			display: flex;
			flex-direction: column;
			transition: $cubic-bezier-1 0.3s all;
			text-decoration: none;

			span.sub {
				font-size: 1.2rem;
				color: $color-text-grey-0;
				margin: 0.5rem 0 0 0;
			}

			.progress__step--name {
				cursor: pointer;
			}

			.progress__step--subtext {
				font-weight: 300;
				font-size: 1.4rem;
				transition: 0.3s $cubic-bezier-1 all;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				max-width: 12rem;
				width: 100%;
				margin: 0 auto;
			}

			&::before {
				content: counter(step);
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 0 auto 1.5rem auto;
				width: 2.6rem;
				height: 2.6rem;
				background-color: lightgrey;
				border: 3px solid lightgrey;
				border-radius: $radius-100percent;
				color: white;
				font-size: 1.6rem;
				transition: $cubic-bezier-1 0.3s all;
				cursor: pointer;
			}

			&::after {
				content: '';
				position: absolute;
				top: 1.5rem;
				left: 50%;
				width: 0;
				height: 3px;
				background-color: $color-main-light-0;
				z-index: -1;
				transition: $cubic-bezier-1 0.3s width;
			}

			span::after {
				content: '';
				position: absolute;
				top: 1.5rem;
				left: 50%;
				width: 100%;
				height: 3px;
				background: lightgrey;
				z-index: -1;
			}

			&:last-child, &:nth-last-child(2) {
				&::after, span::after {
					display: none;
				}
			}

			&.progress__step--done {
				color: $color-black-0;

				&::before {
					content: '✔\fe0e';
					background-color: $color-main-light-0;
					border-color: $color-main-light-0;
				}

				&::after {
					width: 100%;
				}
			}

			&.progress__step--active {
				color: $color-black-0;

				&::before {
					content: counter(step);
					color: $color-text-grey-0;
					background-color: white;
					border-color: $color-main-light-0;
				}
			}
		}
	}

	.progress__step--li.prev, .progress__step--li.next {
		color: white;
		font-size: 3.5rem;
		counter-increment: none;
		color: $color-grey-1;
		font-family: $font-main;
		cursor: pointer;
		display: flex;
		justify-content: center;

		&:hover {
			color: $color-gold-3;
		}

		&::before, &::after {
			content: none;
		}

		span::after {
			content: none
		}
	}

	.progress__step--li.prev {
		margin: 0 10rem 0 0;
		align-items: flex-end;
	}

	.progress__step--li.next {
		margin: 0 0 0 10rem;
		align-items: flex-start;
	}
}

.StepsProgress.thin {

	.order__progress {

		.progress__step--li {
			font-size: 1.4rem;

			&::before {
				width: 2.2rem;
				height: 2.2rem;
				font-size: 1.4rem;
				margin: 0 auto 0.5rem auto;
			}

			.progress__step--subtext {
				font-size: 1.2rem;
				margin-bottom: 0;
			}

			&::after, span::after {
				top: 1.3rem;
			}
		}
	}

	.progress__step--li.prev, .progress__step--li.next {
		font-size: 3.6rem;
	}
}

@media(max-width: 820px) {
	.StepsProgress {
		.progress__step--li.prev {
			margin: 0 5rem 0 0;
		}

		.progress__step--li.next {
			margin: 0 0 0 5rem;
		}
	}
}

@media (max-width: 650px) {
	.StepsProgress {

		.order__progress {
			.progress__step--li {
				&.progress__step {
					display: none;

					&::before {
						content: none;
					}

					&.progress__step--active {
						display: inherit;
					}

					.progress__step--name, .progress__step--subtext {
						&::after {
							content: none;
						}
					}

					.progress__step--name {
						font-size: 1.6rem;
					}
				}

				&.next, &.prev {
					font-size: 2.4rem;
				}
			}
		}
	}
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.StepsProgress {
		position: fixed;
		width: 100%;
		margin: 0;
		margin-bottom: 10rem;
	}
}
