@import './../../../variables.scss';

.QuantityInput {
	--button-size: 3.6rem;
	--padding: .5rem;
	--border-width: 1px;

	display: inline-flex;
	border: 1px solid $color-grey-1;
	border-radius: $radius-quantity-input;
	padding: var(--padding);
	cursor: text;
	font-family: $font-heading;
	height: calc(var(--button-size) + 2 * (var(--padding) + var(--border-width)));

	.quantity__input {
		border: none;
		font-size: 1.6rem;
		text-align: center;
		width: 6rem;
		padding: 0.5rem 0 0.5rem 1rem;
		margin: 0;
		font-family: $font-heading;
		transition: 0.1s $cubic-bezier-1 all;

		outline: none;
		-moz-appearance: textfield;
		&::-webkit-inner-spin-button,
		&::-webkit-outer-spin-button {
			-webkit-appearance: none;
			appearance: none;
			margin: 0
		}

		&.input--length-1 {
			width: 2.2rem;
		}

		&.input--length-2 {
			width: 3.2rem;
		}

		&.input--length-3 {
			width: 3.8rem;
		}

		&.input--length-4 {
			width: 4.8rem;
		}

		&:disabled {
			background-color: transparent;
		}
	}

	.quantity__promo-quantity {
		font-size: 1.6rem;
		display: flex;
		align-items: center;
		color: $color-red-0;
		margin: 0 0 0 .5rem;

		&::before {
			content: '+';
			margin: 0 0.5rem 0 0;
		}

		&::after {
			content: attr(data-free);
			margin: 0 0 0 0.7rem;
			text-transform: uppercase;
		}
	}

	.quantity__order-amount {
		font-size: 1.6rem;
		color: $color-text-grey-1;
		display: flex;
		align-items: center;
		padding: 0 0 0 0.2rem;

		.multiplication-sign {
			font-size: 2.4rem;
			padding: 0 0.3rem 0 0.3rem;
		}
	}

	.quantity__unit {
		font-size: 1.6rem;
		color: $color-text-grey-1;
		display: flex;
		align-items: center;
		padding: 0.2rem 1rem 0.2rem 1rem;
		font-weight: 300;
		text-transform: uppercase;
	}

	.quantity__button {
		font-size: 1.6rem;
		display: flex;
		justify-content: center;
		align-items: center;
		width: var(--button-size);
		height: var(--button-size);
		border: 0.2rem solid $color-gold-3;
		background-color: $color-gold-3;
		color: white;
		border-radius: $radius-100percent;
		cursor: pointer;
		@include user-select(none);
		//transition: 0.3s $cubic-bezier-1 all;

		&:hover {
			background-color: $color-gold-2;
			border-color: $color-gold-2;
		}

		&.disabled {
			display: none;
			background-color: transparent;
			border-color: transparent;
			color: transparent;
			cursor: default;
		}

		&.hidden {
			display: none;
		}
	}

	&.focus {
		border-color: $color-gold-3;
	}

	&.mq {
		border-color: $color-red-0;

		.quantity__input, .quantity__unit {
			color: $color-red-0;
		}
	}

	.mad-quantity {
		margin-top: 0.1rem;

		.mad-quantity__indicator {
			font-size: 1.4rem;
			color: $color-red-0;
		}
	}

	&.compact {
		height: 3.6rem;
		background-color: white;
		margin: 0;
		--button-size: 2.4rem;

		.quantity__button {
			font-size: 1.3rem;
		}

		.quantity__input {
			font-size: 1.4rem;
		}

		.quantity__unit {
			font-size: 1.4rem;
		}

		.quantity__promo-quantity {
			font-size: 1.4rem;
		}

		.quantity__order-amount {
			font-size: 1.4rem;

			.multiplication-sign {
				font-size: 2rem;
			}
		}
	}

	&.big {
		--button-size: 4rem;
		--padding: .3rem;
	}

	&.inconsistent {
		.quantity__input {
			color: $color-grey-2;
		}
	}

	//@media (pointer: coarse) {
	//	--button-size: 4rem;
	//}
}
