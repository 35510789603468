@import './../../variables.scss';

.PreOrders {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 1.8rem 10.2rem 1.8rem;
	font-size: 1.6rem;

	.submit-area {
		position: fixed;
		bottom: 0;
		background-color: white;
		border-top: 1px solid rgb(236, 236, 236);
		width: 100%;
		padding: 1.2rem;
		display: flex;
		align-items: center;
		justify-content: center;
		box-shadow: $box-shadow-1;

		.Button {
			width: 40rem;
			//max-width: 92rem;

			.content {
				justify-content: center;
				color: $color-black-2;
				font-size: 2rem;
			}
		}
	}
}

.PreOrders__submitted-warning {
	p {
		font-size: 1.6rem;
		font-family: $font-main;
		
		span {
			font-weight: 600;
		}
	}

	ul {
		li {
			font-family: $font-heading;
			text-transform: uppercase;
		}
	}
}
