.App {
	height: 100%;
	display: grid;
	grid-template-areas: 'nav main';
	--width-nav-actual: var(--width-nav);

    .Toastify {
		position: absolute;
        right: 0;
        top: 0;
	}

	&.nav--collapsed {
		--width-nav-actual: var(--width-nav-collapsed);
	}
	grid-template-columns: var(--width-nav-actual) calc(100% - var(--width-nav-actual));

	.OGNavigation {
		grid-area: nav;
	}

	main {
		grid-area: main;
		position: relative;
		width: 100%;
		height: 100%;
	}
}

@media (max-width: 650px) {
	.App {
		--width-nav: 0;
		--width-nav-collapsed: 0;
		display: inherit;

		main {
			margin-top: 6rem;
			margin-left: 0;

			&.collapsed {
				margin-top: 6rem;
				margin-left: 0;
			}
		}
	}
}

// override the dropdown styling
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    font-family: "Open Sans", sans-serif !important;
    font-size: 1.35rem !important;
}

