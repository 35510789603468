// CSS VARS


// MEASUREMENTS
// $radius-default: 0;
// $radius-100percent: 0;
// $radius-large-input: 0;
// $radius-badges: 0;
// $radius-checkbox: 0;
// $radius-quantity-input: 0;
// $radius-history: 0;

// $radius-default: 2.5rem;
// $radius-100percent: 100%;
// $radius-large-input: 3.5rem;
// $radius-checkbox: 0.5rem;
// $radius-badges: 1.5rem;
$radius-quantity-input: 4rem;
// $radius-history: 1rem;

$radius-default: 1.2rem;
$radius-100percent: 100%;
$radius-large-input: 1.2rem;
$radius-checkbox: 0.5rem;
$radius-badges: 1.5rem;
// $radius-quantity-input: 1.2rem;
$radius-history: 0.6rem;

// COLORS
$color-main-light-0: #c1ad76;
$color-main-light-1: #f3deb6;
$color-main-light-2: #F3EEE4;

$color-main-dark-0: #665D3F;

$color-gold-0: #B29955;
$color-gold-1: #B59E61;
$color-gold-2: #BCA667;
$color-gold-3: #C1AD76;
$color-gold-4: #CCB986;

$color-orange-0: #DE5C21;
$color-orange-1: #FF9D30;
$color-blue-0: #3885AE;
$color-green-0: #187D53;
$color-green-1: #1c9462;
$color-purple-0: #675583;
$color-red-0: #C1222F;
$color-red-1: #ff2149;
$color-red-2: #c2092b;
$color-yellow-0: #ffc734;

$color-grey-0: #ececec;
$color-grey-1: #d3d3d3;
$color-grey-2: grey;

$color-text-grey-0: #6c757d;
$color-text-grey-1: #6a737b;
$color-text-grey-2: #bebebe;

$color-black-0: #0d181c;
$color-black-1: #21211F;
$color-black-2: #373735;
$color-black-3: #2C2E35;

// COLORS - GENERAL
$color-background: white;

// COLORS - CALENDAR
$color-cal-day-disabled: #b0b9c1;
$color-cal-today: $color-main-light-0;

// COLORS - ALLERGENS
$color-allergen-1: #BA9649;
$color-allergen-2: #E42E5F;
$color-allergen-3: #F7942A;
$color-allergen-4: #2384C6;
$color-allergen-5: #CC6B3F;
$color-allergen-6: #75A941;
$color-allergen-7: #70C6EF;
$color-allergen-7_1: #70C6EF;
$color-allergen-7_2: #172a30;
$color-allergen-8: #835230;
$color-allergen-9: #259F67;
$color-allergen-10: #D88D2A;
$color-allergen-11: #DAB06F;
$color-allergen-12: #8463A4;
$color-allergen-13: #C17290;
$color-allergen-14: #A3948A;

// COLOR - OTHERS
$color-badge-default: $color-blue-0;
$color-pdf: #e73c34;
$color-eob: $color-blue-0;
$color-season: $color-green-1;
$color-time: $color-orange-0;
$color-pending: $color-orange-0;
$color-in-order: $color-green-1;
$color-promo: $color-red-0;
$color-pending: $color-black-2;
$color-buylist-indicator: $color-yellow-0;
$color-reserved: $color-blue-0;
$color-in-the-picture: $color-purple-0;

$color-background-new-products: linear-gradient(90deg, $color-gold-1, $color-gold-4);
$color-new-products: white;

// FONTS
$font-main: 'Open Sans', sans-serif;
$font-heading: Oswald, sans-serif;

// BOX SHADOWS
$box-shadow-1: 0 0 1.5rem 0 rgba(0,0,0,.2);
$box-shadow-2: 0 0.5rem 1.5rem 0 rgba(0, 0, 0, .2);

// OTHERS
$cubic-bezier-1: cubic-bezier(0.4, 0.0, 0.2, 1);

// MIXINS
@mixin user-select($value) {
	-webkit-user-select: $value;
	-moz-user-select: $value;
	-ms-user-select: $value;
	-o-user-select: $value;
	user-select: $value;
}

//@media width
$screen-xs: 4.125rem;
$screen-s: 23.438rem;
$screen-m: 37.5rem;
$screen-l: 41.688rem;
$screen-lm: 44.5rem;
$screen-xl: 60rem;
$screen-xll: 60.5rem;
$screen-xxl: 73.75rem;
$screen-xxls: 73.125rem;
$screen-xxlm: 85.313rem;
$screen-xxll: 98.25rem;


// KEYFRAMES
@keyframes promo-awarded-grid {
	0% {
		top: -5rem;
	}

	100% {
		top: 1rem;
	}
}

@keyframes promo-awarded-list {
	0% {
		margin-bottom: -4.8rem;
	}

	100% {
		margin-bottom: 0.4rem;
	}
}

@keyframes promo-awarded-list-compact {
	0% {
		margin-bottom: -3.4rem;
	}

	100% {
		margin-bottom: 0.4rem;
	}
}

@keyframes rotate-bounce {
	0% {
		transform: rotate(0deg) scale(1);
	}

	50% {
		transform: rotate(15deg) scale(1.3);
	}

	100% {
		transform: rotate(0deg) scale(1);
	}
}

@keyframes fade {
	0% {
		background-color: transparent;
	}

	100% {
		background-color: rgba($color-black-2, 0.5);
	}
}

@keyframes grow {
	0% {
		transform: scale(0);
		//opacity: 0;
	}

	100% {
		transform: scale(1);
		//opacity: 1;
	}
}

@keyframes loading-breathing {
	0% {
		background-color: rgba($color-black-2, 0.02);
	}

	90% {
		background-color: rgba($color-black-2, 0.10);
	}

	100% {
		background-color: rgba($color-black-2, 0.10);
	}
}

@keyframes appear {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes showDelayed {
	to {
		visibility: visible;
	}
}
