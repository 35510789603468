@import './../../../variables.scss';

.ActiveFilters__item {
	padding: 0.3rem 1.4rem;
	font-size: 1.4rem;
	text-transform: uppercase;
	border-radius: $radius-default;
	background-color: rgba(0, 0, 0, 0.08);
	color: rgba(0, 0, 0, 0.6);
	cursor: pointer;
	margin-bottom: 0.5rem;
	user-select: none;
	white-space: nowrap;
	font-family: $font-heading;

	&:not(:last-of-type) {
		margin-right: 0.5rem;
	}

	display: flex;
	justify-content: center;
	align-items: center;

	&:hover {
		text-decoration: line-through;
		opacity: 0.6;
	}

	svg {
		margin-left: 0.75rem;
		cursor: pointer;
		height: 2.4rem;
		//color: $color-black-2;
	}

	&.allergens {
		background-color: rgba(#bd4c72, 0.3);
		color: #bd4c72;
	}

	&.promo {
		background-color: rgba($color-promo, 0.2);
		color: $color-promo;
	}

	&.eob {
		background-color: rgba($color-eob, 0.2);
		color: $color-eob;
	}

	&.season {
		background-color: rgba($color-season, 0.2);
		color: $color-season;
	}

	&.timeSensitive {
		background-color: rgba($color-time, 0.2);
		color: $color-time;
	}

	&.inOrder {
		background-color: rgba($color-in-order, 0.2);
		color: $color-in-order;
	}

	&.pending {
		background-color: rgba($color-pending, 0.2);
		color: rgba($color-pending, 0.8);
	}

	&.reserved {
		background-color: rgba($color-reserved, 0.2);
		color: $color-reserved;
	}

	&.shopcode {
		cursor: inherit;

		&:hover {
			text-decoration: inherit;
			opacity: inherit;
		}

		.title {
			margin-right: 0.5rem;
			//display: none;
		}

		.shopcode__level {
			//font-family: 'Open Sans', sans-serif;
			font-weight: 300;
			font-size: 1.6rem;
			text-transform: lowercase;

			&:not(:last-of-type) {
				cursor: pointer;

				&::after {
					content: '>';
					margin: 0 0.5rem;
				}
			}

			&:hover {
				//text-decoration: underline;

				&::after {
					opacity: 0.4;
					//text-decoration: line-through;
				}
			}

			&:hover~span {
				//text-decoration: line-through;
				opacity: 0.4;

				&::after {
					//text-decoration: line-through;
					//opacity: 0.4;
					// content: '>';
					// margin: 0 0.5rem;
				}
			}
		}
	}

	&.query {
		font-weight: 300;

		.title {
			font-weight: 400;
		}
	}

	&.campaigns {
		background-color: rgba($color-gold-1, 0.3);
		color: $color-gold-2;
	}

	&.endofyear2020 {
		background-color: rgba($color-gold-1, 0.3);
		color: $color-gold-2;
	}
}

.ActiveFilters__info {
	display: flex;
	//font-family: $font-main;
	height: 100%;
	justify-content: center;
	align-items: center;
	font-size: 1.4rem;
	color: gray;
	margin-left: 1.5rem;
	font-weight: 300;
	white-space: nowrap;
	font-family: $font-heading;

	&.clear-filters {
		text-decoration: underline;
		cursor: pointer;
	}
}