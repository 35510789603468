@import './../../variables.scss';

.TradeFairSummary {
    display: flex;
    flex-direction: column;
    width: 90rem;
    max-width: 95vw;

    .CharlesTitle {
        margin-bottom: 1.8rem;
    }

    .info {
        background-color: $color-black-1;
        color: white;
        font-family: $font-main;
        font-size: 1.6rem;
        padding: 1.2rem 1.6rem;
        border-radius: 1.2rem;
        width: 100%;
    }

    .tabs {
        margin: 1.2rem 0;
        width: 100%;
        display: flex;
        gap: 0.6rem;
    }

    .summary-products {
        display: flex;
        flex-direction: column;
    }

    .summary-section {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        padding: 1.2rem 0;

        .title {
            font-size: 2.4rem;
            font-family: $font-heading;
            text-transform: uppercase;
            margin-bottom: 1.2rem;
        }

        .no-products {
            font-size: 1.6rem;
            padding: 0;
        }
    }

    .disclaimer {
        padding: 1.2rem 0;
        font-size: 1.2rem;
        color: #9D9D9D;
        display: flex;
        flex-direction: row;
        gap: 0.6rem;
    }

    .actions {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        margin-top: 1.5rem;
        align-items: flex-end;

        .Button .content {
            color: white;
            min-width: 16rem;
            justify-content: center;

            .Spinner .circle {
                border-bottom-color: white;
                border-right-color: white;
            }
        }

        .totals {
            margin-left: auto;
            padding-left: 1rem;

            .quantity__total {
                display: inline-block;
                max-width: 20rem;
                width: 100%;
                font-family: Oswald, sans-serif;
                font-size: 1.8rem;
                text-transform: uppercase;
                text-align: right;

                span {
                    color: rgba($color-black-2, 0.6);
                }
            }
        }
    }
}

.notification {
    margin-left: 1.2rem;
    font-size: 1.4rem;

    &.error {
        color: $color-red-0;
    }
}

.PortalModal.TradeFairSummaryModal,
.Modal.TradeFairSummaryModal {
    .modal__body {
        overflow-y: scroll;
        max-height: 90vh;
    }
}

@media (max-width: 480px) {

    .PortalModal.TradeFairSummaryModal,
    .Modal.TradeFairSummaryModal {
        .modal__body {
            padding: 1.2rem;
            max-height: 100vh;

            .actions {
                flex-direction: column-reverse;
            }
        }

        .modal__close {
            position: fixed;
        }
    }
}