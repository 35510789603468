@import './../../variables.scss';

.TempNotAvailable {
	// color: white;
	background-color: #F0ECEC;
	border-radius: 0.6rem;
	max-width: 170px;

	.header {
		display: flex;
		font-size: 1.4rem;
		background-color: #E0DEDE;
		border-radius: 0.6rem;
		align-items: center;
		justify-content: center;
		text-transform: uppercase;
		padding: 0rem 0.5rem;
	}

	.body {
		padding: 0.5rem;
		font-size: 1.2rem;
		align-items: center;
		justify-content: center;
		display: flex;
		text-align: center;
	}
}

.TempNotAvailableCompact {
	max-width: 100px;
}