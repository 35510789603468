@import './../../../variables';

.ProductSelectionStep {
	margin: 0 0 2rem 0;
	padding: 0 1rem;

	.promo__countdown {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 2rem 0;
		//display: none;

		.info__top {
			display: none;
		}

		.promo__filter-on {
			display: none;
		}

		.cta__promo-filter, .cta__promo-filter--off {
			font-family: $font-heading;
			font-size: 1.6rem;
			text-transform: uppercase;
			background-color: white;
			border: 0.2rem solid $color-black-2;
			color: $color-black-2;
			border-radius: $radius-default;
			padding: 1rem 2.5rem;
			font-weight: 400;
			cursor: pointer;
			margin-top: 0;
			box-shadow: $box-shadow-2;

			span {
				color : $color-promo;
				//font-style: italic;
				font-weight: 500;
			}
		}
	}

	.products__body {
		display: flex;
		max-width: 130rem;
		width: 100%;
		margin: 0 auto;
	}

	.products__filter {
		font-family: $font-heading;
		max-width: 130rem;
		width: 100%;

		.Search {
			padding: 0;
		}
	}

	.products__list {
		width: 100%;
		position: relative;

		.fetching__spinners {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			font-family: $font-heading;
			font-size: 1.6rem;
			text-transform: uppercase;
			margin: 3rem 0 0 0;
			font-weight: 300;
			position: absolute;
			width: 100%;

			.spinner {
				display: flex;
				width: 11rem;
				padding: 0.5rem 0;

				.Spinner {
					width: 2rem;
				}

				.icon {
					color: $color-green-1;
					min-width: 3rem;
				}
			}
		}

		& > .in-the-picture {
			border: 2px solid $color-in-the-picture;
			padding: .5rem 1rem .5rem .5rem;
			margin: -.5rem -.5rem .6rem;
			border-radius: 15px;
			background-color: transparentize($color-in-the-picture, .8);

			h3 {
				font-family: $font-heading;
				text-transform: uppercase;
				font-size: 2.5rem;
				font-weight: 400;
				color: $color-in-the-picture;
				text-align: center;
				margin-block: 1rem;
			}
		}
	}

	.scrollToTopButton {
		background-color: white;
		border: none;
		border-radius: 50%;
		width: 5rem;
		height: 5rem;
		position: fixed;
		bottom: 2rem;
		box-shadow: 0 0 1rem rgba(0, 0, 0, .2);
		cursor: pointer;
		transition: .2s;
		text-align: center;
		margin-left: -8rem;
		&:hover {
			box-shadow: 0 0 1.5rem rgba(0, 0, 0, .3);
			padding-bottom: 1rem;
		}
	}
}

.ProductSelectionStep--new {
	.products__body {
		width: 100%;
		max-width: 130rem;
		display: grid;
		grid-template-areas:
			'banner banner'
			'filter list';
		grid-template-columns: 25rem auto;
		row-gap: 2rem;
		column-gap: 2rem;

		.HeroBanners {
			grid-area: banner;

			.secondary {
				gap: 1rem;

				> div, > a {
					flex: 1 1 0;
				}
			}
		}

		.ProductsFilters {
			grid-area: filter;
		}

		.products__list {
			grid-area: list;
		}
	}
}

@media (max-width: 1040px) {
	.ProductSelectionStep--new {
		.products__body {
			grid-template-areas:
				'banner'
				'filter'
				'list';
			grid-template-columns: auto;
		}

	}
}
