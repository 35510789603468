@import './../../variables.scss';

.Alerts {
	min-width: 100%;
	position: relative;
	z-index: 9;

	.Alert {
		height: 4.8rem;
		background-color: $color-black-1;
		color: white;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 1.4rem;
		font-family: $font-heading;
		text-transform: uppercase;
		min-width: 100%;
		//font-weight: 600;
	}

	button.link {
		border: none;
		background-color: transparent;
		font-size: 1.4rem;
		font-family: $font-heading;
		text-transform: uppercase;
		color: white;
		text-decoration: underline;
		cursor: pointer;
	}

	.VersionAlert {
		position: fixed;
		svg {
			color: $color-red-1;
			margin-right: 1rem;
		}

		.Spinner .circle {
			border-color: white;
			border-top-color: transparent;
			border-left-color: transparent;
		}
	}
}