@import './../../variables';

.Auth {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100vw;
	background-color: white;

	@media(orientation:landscape){
		height: auto;
	}

	@media(min-width: $screen-xs) and (max-width: $screen-m) {
		margin-top: 2rem;
	}

	@media (min-width: $screen-xs) and (max-width: $screen-l) and (orientation:landscape){
		margin-top: 15rem;
	}

	@media (min-width: $screen-l) and (max-width: $screen-xl) and (orientation:landscape){
		margin-top: 15rem;
	}
	@media (min-width: $screen-xl) and (max-width: $screen-xxl) and (orientation:landscape){
		margin-top: 10rem;
	}
	.charles-logo {
		height: 10rem;
		@media(min-width: $screen-xs) and (max-width: $screen-m) {
			height: 4rem;
		}
		@media(min-width: $screen-s) and (max-width: $screen-m) {
			height: 8rem;
		}
		@media(min-width: $screen-xxl) and (max-width: $screen-xxll) {
			margin-top: 3rem;
		}
		@media(min-width: $screen-m) and (max-width: $screen-xl){
			height: 9rem;
		}
	}

	.maintenance-mode-message {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 1.4rem;
		max-width: 80rem;

		h1 {
			font-family: $font-heading;
			font-size: 3rem;
			text-transform: uppercase;
			color: $color-black-2;
			font-weight: 500;
			margin: 1rem 0;

			@media(min-width: $screen-xs) and (max-width: $screen-s) {
				font-size: 0.8rem;
			}
			@media(min-width: $screen-s) and (max-width: $screen-m) {
				font-size: 1.4rem;
			}
			@media(min-width: $screen-m) and (max-width: $screen-l) {
				font-size: 2.2rem;
			}
		}

		h2 {
			font-family: $font-heading;
			font-size: 1.6rem;
			text-transform: uppercase;
			color: $color-black-2;
			font-weight: 400;
			margin: 0;
			text-align: center;

			&:after {
				content: none;
			}

			@media(min-width: $screen-xs) and (max-width: $screen-s) {
				font-size: 0.6rem;
			}

			@media(min-width: $screen-s) and (max-width: $screen-m) {
				font-size: 1.2rem;
			}
			@media(min-width: $screen-m) and (max-width: $screen-l) {
				font-size: 2rem;
			}
		}

		p {
			font-size: 1.6rem;
			max-width: 60rem;
			margin: 1rem 0 0 0;
		}
	}

	.auth-form {
		background-color: white;
		box-shadow: $box-shadow-1;
		padding: 3rem 3rem 2.5rem 3rem;
		margin: 2rem 0 0 0;
		width: 50rem;
		display: flex;
		flex-direction: column;
		position: relative;
		border-radius: 3rem;

		@media(min-width: $screen-xs) and (max-width: $screen-s) {
			width: 95%;
			padding: 1.5rem;
		}

		@media(min-width: $screen-s) and (max-width: $screen-m) {
			width: 80%;
		}
		
		h1 {
			text-align: center;
			text-transform: uppercase;
		}

		.form {
			display: flex;
			flex-direction: column;

			p {
				font-size: 1.6rem;
			}

			label {
				display: flex;
				flex-direction: column;
				text-indent: 2rem;
				text-transform: uppercase;
				font-size: 1.6rem;
				color: rgb(108, 117, 125);
				font-family: $font-heading;

				@media(min-width: $screen-xs) and (max-width: $screen-s) {
					font-size: 1.1rem;
				}

				@media(min-width: $screen-s) and (max-width: $screen-m) {
					font-size: 1.5rem;
				}
			}

			input {
				padding: 1rem 2rem;
				border: 1px solid rgba(236, 236, 236, 1);
				border-radius: 2.5rem;
				font-size: 2rem;
				margin: 0 0 2rem 0;
				font-family: 'Oswald', sans-serif;
				background-color: transparent;
				transition: 0.3s $cubic-bezier-1 all;

				@media(min-width: $screen-xs) and (max-width: $screen-s) {
					margin: 0 0 1rem 0;
				}
			}

			ul.form__errors {
				list-style: none;
				font-size: 1.4rem;
				color: $color-red-0;

				li {
					padding: 0 1rem;
					margin: 1rem 0;
					border-left: 0.3rem solid $color-red-0;
				}
			}

			button,
			input[type="submit"] {
				text-transform: uppercase;
				cursor: pointer;
				color: $color-black-2;
				transition: 0.2s $cubic-bezier-1 all;
				font-family: $font-heading;
				font-weight: 400;
				display: flex;
				justify-content: center;
				align-items: center;
				text-align: center;

				padding: 1rem 0;
				margin: 1rem 0 0 0;
				font-size: 2rem;
				border-radius: 3rem;
				background-color: $color-main-light-0;
				border: none;

				@media(min-width: $screen-xs) and (max-width: $screen-s) {
					font-size: 1.1rem;
				}

				@media(min-width: $screen-s) and (max-width: $screen-m) {
					font-size: 1.5rem;
				}

				&:hover {
					box-shadow: $box-shadow-1;
					color: $color-black-2;
					background-color: $color-main-light-1;
				}

				&.disabled,
				&:disabled {
					cursor: inherit;
					opacity: 0.6;

					&:hover {
						color: inherit;
						box-shadow: none;
					}
				}
			}

			.email-sent-hint {
				text-align: center;
				font-size: 1.8rem;
				font-family: $font-heading;
				font-weight: 300;
			}
		}
	}

	.auth__locale {
		display: flex;
		justify-content: center;
		margin: 5rem 0 0 0;

		button {
			border: none;
			background-color: transparent;
			cursor: pointer;
			margin: 0 1rem 0 0;
			padding: 0;
			font-family: $font-heading;
			text-transform: uppercase;
			font-size: 1.6rem;
			font-weight: 600;
		}
	}

    .auth-exception {
        min-width: 100px;
        max-width: 100%;
        margin: 0 auto 2.5rem;
        color: var(--toastify-icon-color-error, red);
        text-align: center;
        font-size: 1.35rem;
        padding: 0.65rem 1rem;
        border-radius: 0.5rem;

        svg[data-prefix=fas] {
            margin-right: 0.65rem;
        }
    }
}