@import './../../variables.scss';

.PriceChanges {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 2rem;
	font-family: $font-heading;
	font-weight: 300;

	.Search {
		//margin-bottom: 2rem;
		max-width: 100rem;
	}

	.changes__list {
		width: 100%;
		margin: 0 2rem;
		max-width: 100rem;

		.list__groups {
			border: 1px solid $color-grey-0;
			border-radius: $radius-default;
		}

		.changes__group {
			border-bottom: 1px solid $color-grey-0;

			&:first-of-type {
				border-top-right-radius: $radius-default;
				border-top-left-radius: $radius-default;
			}

			&:last-of-type {
				border-bottom: none;
				border-bottom-right-radius: $radius-default;
				border-bottom-left-radius: $radius-default;
			}

			&:nth-child(even) {
				background-color: rgba($color-grey-0, 0.2);

				&:hover {
					background-color: rgba($color-grey-0, 0.4);
				}
			}

			&:hover {
				background-color: rgba($color-grey-0, 0.1);
			}

			&.changes__group--expanded {
				.list__item {
					border-bottom: 1px solid $color-grey-0;
				}

				.group__changes {
					display: inherit;
				}
			}

			.list__item {
				display: grid;
				grid-template-columns: 4rem 20rem 1fr;

				width: 100%;
				padding: 1.5rem 2.5rem;
				font-size: 1.6rem;
				
				font-family: $font-heading;
				font-weight: 300;
				cursor: pointer;
			}

			.group__changes {
				display: none;

				font-family: $font-heading;
				font-weight: 300;
				font-size: 1.6rem;
				padding: 2.5rem 2.5rem 2.5rem 6.5rem;
				--price-change-columns: 1fr repeat(4, 10rem);

				.changes__header {
					display: grid;
					grid-template-columns: var(--price-change-columns);
					column-gap: 2.5rem;
					text-transform: uppercase;
					font-weight: 400;

					.header--numeric {
						text-align: right;
					}
				}

				.changes__item {
					display: grid;
					grid-template-columns: var(--price-change-columns);
					column-gap: 2.5rem;
					padding: 0.5rem 0;

					.change__amount {
						font-weight: 400;
						width: 100%;
						display: flex;
						align-items: center;
					}

					.Currency {
						text-align: right;
						width: 100%;
						display: inline-block;
					}

					.Spinner {
						align-items: flex-start;
					}

					.change__product {
						display: flex;
						align-items: center;

						&.change__product--no-code {
							.product__code {
								color: rgba($color-text-grey-0, 0.4);
							}
						}
						
						.product__name {
							font-weight: 400;
						}

						.product__code {
							color: $color-text-grey-0;
							font-size: 1.4rem;
							font-weight: 400;
						}

						div	{
							margin: 0 1rem 0 0;
						}
					}
				}
			}
		}
	}
}
