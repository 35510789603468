@import './../../variables.scss';

.ProductsHeader {
	display: flex;
	flex-direction: column;

	.list-controls {
		grid-area: controls;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.Search {
			padding: 0;

			input {
				height: 5rem;
				font-size: 1.6rem;
				padding: 1rem 2rem;
			}
		}

		.list-options {
			display: flex;
			align-items: center;
			margin: 0 1rem 0 2rem;
			gap: 1rem;

			.list__sorting {
				display: flex;
				align-items: center;
				font-size: 1.4rem;
				font-family: $font-heading;
				font-weight: 300;
				text-transform: uppercase;
				white-space: nowrap;

				.sort__direction {
					white-space: nowrap;
				}

				&.list__sorting--disabled {
					opacity: 0.3;

					.sort__direction {
						cursor: default;
					}
				}

				.Dropdown {
					margin: 0 0 0 1rem;
				}

				.sort__direction {
					font-size: 2rem;
					margin: 0 1rem 0 1rem;
					color: lightgrey;
					cursor: pointer;

					.direction {
						margin: 0 0.5rem;

						&.direction--active {
							color: $color-gold-3;
						}
					}
				}
			}

			.list__view {
				display: flex;
				justify-content: flex-end;
				height: 100%;
				align-items: center;

				.view__button {
					background-color: transparent;
					border: none;
					font-size: 2rem;
					cursor: pointer;
					color: rgba($color-grey-1, 1);
					margin: 0 1rem;

					&:last-of-type {
						margin-right: 0;
					}

					&.view--active {
						color: $color-gold-3;
					}
				}
			}
		}
	}

	.filter-info {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;
		margin: 1rem 0;
		min-height: 3.5rem;

		.num-products {
			white-space: nowrap;
			margin: 0 1rem;
			//display: inline-block;
			font-size: 1.4rem;
			text-transform: uppercase;
			font-family: $font-heading;
			font-weight: 300;
		}
	}
}

@media (max-width: 1380px) {
	.ProductsHeader {
		.list-controls {
			flex-direction: column;

			.list-options {
				margin-top: 1rem;
				justify-content: space-between;
				margin-left: 1rem;

				.Search {
					min-width: 20rem;
				}
			}
		}
	}
}

//@media (max-width: 650px) {
//	.ProductsHeader {
//		.list-controls {
//			.list-options {
//				.list__view {
//					display: none;
//				}
//			}
//		}
//	}
//}
