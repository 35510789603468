@import './../../variables.scss';

.Modal {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	z-index: 999;

	.modal__backdrop {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba($color-black-2, 0.5);
		animation: fade 0.2s $cubic-bezier-1 1;
		z-index: 999;
	}

	.modal__body {
		z-index: 1000;
		background-color: white;
		padding: 3.5rem;
		box-shadow: $box-shadow-1;
		animation: grow 0.2s $cubic-bezier-1 1;
		position: relative;
		border-radius: $radius-default;
		overflow: hidden;

		.modal__close {
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 2rem;
			color: $color-black-2;
			cursor: pointer;
			position: absolute;
			top: 1.2rem;
			right: 1.5rem;
			width: 3rem;
			height: 3rem;
			z-index: 99;
		}
	}
}

@media (max-width: 480px) {
	.Modal {
		z-index: 999;

		.modal__body {
			border-radius: 0;
			width: 100vw;
			height: 100vh;
			padding: 0;

			.modal__close {
				top: 0.5rem;
				right: 0.5rem;
				font-size: 3rem;
			}
		}
	}
}