@use '../../variables' as *;

* {
	box-sizing: border-box;
	outline: none;
}

html,
body {
	width: 100%;
	height: 100%;
	-webkit-overflow-scrolling: touch;
}

html {
	font-size: 10px;
	scroll-behavior: smooth;

	--width-nav: 32rem;
	--width-nav-collapsed: 6rem;
}

body {
	margin: 0;
	padding: 0;
	font-family: sans-serif;
	background-color: $color-background;
	color: $color-black-2;
}

#root {
	height: 100%;
}

::selection {
	background-color: $color-black-2;
	color: white;
}