@import './../../variables.scss';

.OGDatePicker {
	.calendar {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: flex-start;
		flex-wrap: wrap;
		height: 100%;
		animation: appear 0.3s ease-in-out 1;

		.month__prev,
		.month__next {
			width: 4rem;
			height: 4rem;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 2.4rem;
			border-radius: 10rem;

			&:hover {
				color: white;
				background-color: $color-main-light-0;
			}
		}

		.calendar__month {
			margin: 0 2rem;

			.month__name {
				font-size: 1.8rem;
				font-family: $font-heading;
				margin: 2rem 0;
				text-transform: uppercase;
				width: 28rem;
				text-align: center;
			}

			.calendar__weekdays {
				user-select: none;
				width: 28rem;
				display: flex;
				font-size: 1.2rem;
				color: $color-grey-2;

				span {
					width: 4rem;
					height: 4rem;
					display: flex;
					justify-content: center;
					align-items: center;
					font-family: $font-heading;
					text-transform: uppercase;
				}
			}

			.calendar__days,
			.calendar__legend {
				user-select: none;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				width: 28rem;

				.calendar__day {
					width: 3.6rem;
					height: 3.6rem;
					margin: 0.2rem;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 1.5rem;
					font-family: $font-heading;
					cursor: pointer;
					border-radius: $radius-100percent;
					position: relative;
					transition: 0.2s $cubic-bezier-1 all;
					border: 1px solid rgba($color-gold-3, 1);



					&.calendar__day--default-delivery {
						border: 2px solid $color-gold-0;
					}

					&.calendar__day--selected {
						background-color: $color-black-2;
						color: white;
						border-color: $color-black-2;
					}

					&.calendar__day--disabled {
						color: rgba($color-cal-day-disabled, 0.4);
						cursor: default;
						border: none;
					}

					&.calendar__day--today {
						color: white;
						background-color: $color-gold-3;
						font-weight: 500;
						cursor: default;
					}

					&.calendar__day--hidden {
						color: transparent;
						background-color: transparent;
						cursor: default;
						border: none;
					}

					&:hover {
						background-color: rgba($color-black-2, 0.9);
						color: white;
						border-color: rgba($color-black-2, 0.9);
					}

					&.calendar__day--selected:hover {
						background-color: $color-black-2;
						color: white;
					}

					&.calendar__day--disabled:hover {
						background-color: inherit;
						color: rgba($color-cal-day-disabled, 0.4);

						&:after {
							transform: none;
						}
					}

					&.calendar__day--today:hover {
						color: white;
						background-color: $color-gold-3;
					}

					&.calendar__day--hidden:hover {
						color: transparent;
						background-color: transparent;
					}
				}
			}
		}
	}

	&.disabled {
		.calendar {
			.calendar__month {

				.calendar__days,
				.calendar__legend {
					.calendar__day {
						cursor: default;

						&:hover {
							background-color: inherit;
							color: inherit;
							border-color: inherit;
						}

						&.calendar__day--disabled:hover {
							color: $color-cal-day-disabled;
						}

						&.calendar__day--today:hover {
							color: white;
							background-color: $color-gold-3;
						}

						&.calendar__day--selected:hover {
							background-color: $color-black-2;
							color: white;
						}
					}
				}
			}
		}
	}

	.loading__calendar {
		display: flex;
		height: 100%;
		margin: 1rem;

		.breathing {
			border-radius: $radius-default;
			animation-name: loading-breathing;
			animation-duration: 2s;
			animation-iteration-count: infinite;
			animation-timing-function: ease-in-out;
			animation-direction: alternate;
		}

		.loading__month {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: 0 2rem;
			height: 100%;

			.loading__month-name {
				width: 20rem;
				height: 2.6rem;
				margin: 2rem 0;
			}

			.loading__weekdays {
				width: 28rem;
				display: flex;
				font-size: 1.2rem;
				margin: 1rem 0;

				span {
					width: 3rem;
					height: 3rem;
					display: flex;
					justify-content: center;
					align-items: center;
					margin: 0 0.5rem;
				}
			}

			.loading__days {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				width: 28rem;

				.loading__day {
					width: 4rem;
					height: 3.6rem;
					margin: 0.2rem 0;
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 0;

					&:first-of-type {
						border-top-left-radius: $radius-default;
						border-bottom-left-radius: $radius-default;
					}

					&:last-of-type {
						border-top-right-radius: $radius-default;
						border-bottom-right-radius: $radius-default;
					}
				}

				.loading__day--invisible {
					width: 4rem;
					height: 3.6rem;
					margin: 0.2rem 0;
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 0;
				}
			}
		}
	}
}

@media (max-width: 920px) {
	.DatePicker {

		.calendar,
		.loading__calendar {
			flex-direction: column;
		}
	}
}
