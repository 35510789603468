@import './../../variables.scss';

.OGNavigation {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	min-width: var(--width-nav);
	max-width: var(--width-nav);
	background-color: $color-black-1;
	overflow-y: auto;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	z-index: 99;

	.nav__top, .nav__bottom {
		display: flex;
		flex-direction: column;
	}

	.nav__brand {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 4rem;
		margin: 2rem 0 1rem 0;

		.brand__image-and-version {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.brand__image {
			img {
				height: 6rem;
			}
		}

		.nav__collapse {
			cursor: pointer;
		}

		.hamburger-menu {
			display: none;
			cursor: pointer;
		}
	}

	.nav__icon {
		font-size: 1.6rem;
		color: white;
		min-width: 3.6rem;
		width: 3.6rem;
		height: 3.6rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: $radius-default;

		img {
			width: 2.2rem;
			height: 2.2rem;
		}
	}

	.nav__breaker {
		width: calc(100% + 2rem);
		background-color: $color-gold-3;
		height: 0.5rem;
		min-height: 0.5rem;
		transform: rotate(-3deg);
		margin: 1.7rem 0 2rem -1rem;
	}

	.nav__user {
		display: flex;
		flex-direction: column;
		color: white;
		font-size: 1.6rem;
		font-family: $font-heading;
		margin: 1rem 0 0 0;
		text-transform: uppercase;

		.user__item {
			color:white;
			font-size: 1.6rem;
			font-family: $font-heading;
			text-transform: uppercase;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			margin: 0;
			padding: 0rem 4rem;
			cursor: pointer;
			text-decoration: none;
			transition: 0.1s $cubic-bezier-1 color;

			&:hover {
				color: $color-gold-3;

				.nav__icon {
					color: $color-gold-3;
				}
			}

			.item__description {
				display: flex;
				align-items: center;
				height: 3.6rem;
				margin: 0 1rem 0 0;
				width: 100%;
				line-height: 1;

				.decsription__icon {
					margin: 0 1rem 0 0;
					min-width: 2.4rem;
					width: 2.4rem;
					display: flex;
					justify-content: center;
				}
			}
		}

		.user__logout {
			font-size: 1.4rem;
			margin-left: 3.4rem;
		}
	}

	.nav__items {
		display: flex;
		flex-direction: column;
		color: white;
		font-size: 2rem;
		font-family: $font-heading;
		list-style: none;
		padding: 0 4rem;
		margin: 1rem 0 0 0;
		text-transform: uppercase;

		.nav__item {
			padding: 0.5rem 0;
			transition: 0.1s $cubic-bezier-1 color;

			&:hover {
				color: $color-gold-3;

				.nav__icon {
					color: $color-gold-3;
				}
			}

			.nav__link {
				text-decoration: none;
				display: flex;
				justify-content: space-between;
				align-items: center;

				&.nav__link--active {
					color: $color-gold-1;

					.nav__icon {
						background-color: $color-black-2;
						color: $color-gold-1;
					}
				}
			}
		}
	}

	.nav__bottom {
		padding: 0 4rem 2rem 4rem;
		color: white;
		font-size: 1.4rem;
		font-family: $font-main;
		font-weight: 600;

		.nav__contact {
			.contact__address {

			}

			.contact__details {
				margin: 2rem 0;
				display: flex;
				flex-direction: column;
				align-items: flex-start;

				.detail__telephone,
				.detail__email {
					display: flex;
					text-decoration: none;
				}

				.detail__message {
					display: flex;
					text-decoration: none;
					border-radius: $radius-default;
					background-color: $color-black-2;
					padding: 1rem 2rem;
					margin: 2rem 0 0 0rem;
					font-family: $font-heading;
					text-transform: uppercase;
					font-weight: 500;
				}

				.detail__version {
					display: flex;
					text-decoration: none;
				}
			}

			.contact__icon {
				margin: 0 1.3rem 0 0;
				font-size: 1.4rem;
			}
		}

		.nav__terms {

		}
	}
}

.OGNavigation--collapsed {
	width: 6rem;
	min-width: 6rem;
	max-width: 6rem;
	overflow-y: hidden;

	.nav__brand {
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 0;

		.brand__image {
			text-align: center;

			img {
				width: calc(100% - 1rem);
				height: auto;
			}
		}
	}

	.nav__breaker {
		margin: -0.5rem 0 0.5rem -1rem;
	}

	.nav__items, .nav__user {
		padding: 0;
		flex-direction: column;
		align-items: center;

		.nav__item, .user__item {
			padding: 0.5rem 0;

			.item__description {
				display: none;
			}

			&.user__logout {
				margin-left: 0;
			}
		}
	}

	.nav__items {
		margin-top: 1rem;

		.nav__icon {
			font-size: 1.8rem;
		}
	}

	.nav__user {
		.user__item {
			padding: 0;
		}
	}

	.nav__bottom {
		display: none;
	}
}

@media (max-width: 650px) {
	.OGNavigation {
		width: 100%;
		max-width: 100%;
		height: 100vh;
		min-height: 100vh;
		transition: 0.3s $cubic-bezier-1 all;
		z-index: 99;

		.nav__brand {
			flex-direction: row-reverse;
			justify-content: space-evenly;
			//align-items: flex-start;
			padding: 1rem 0;
			margin: 0;
			height: 100%;

			.spacer {
				width: 3.6rem;
			}

			.brand__image {
				img {
					height: 4rem;
					width: auto;
					transition: 0.1s $cubic-bezier-1 all;
				}
			}

			.nav__collapse {
				display: none;
			}

			.hamburger-menu {
				display: flex;
				flex-direction: column;
				align-items: center;
				height: 2.4rem;
				width: 3.1rem;
				position: relative;

				&> span {
					position: absolute;
					width: 100%;
					height: 0.2rem;
					background-color: white;
					transition: 0.3s $cubic-bezier-1 all;

					&.line--1 {
						transform: rotate(45deg);
						top: 1.1rem;
					}

					&.line--2 {
						width: 0%;
						top: 1.1rem;
					}

					&.line--3 {
						transform: rotate(-45deg);
						top: 1.1rem;
					}
				}
			}
		}
	}

	.OGNavigation--collapsed {
		height: 6rem;
		min-height: 6rem;
		width: 100%;
		min-width: 100%;
		max-width: 100%;

		.nav__top {
			height: 100%;
		}

		.nav__brand {
			height: 6rem;

			.hamburger-menu {
				&> span {
					&.line--1 {
						top: 0.4rem;
						transform: none;
					}

					&.line--2 {
						width: 100%;
					}

					&.line--3 {
						top: 1.8rem;
						transform: none;
					}
				}
			}
		}


		.nav__breaker,
		.nav__user,
		.nav__items,
		.nav__bottom {
			display: none;
		}
	}
}
