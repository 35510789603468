@import './../../variables.scss';

.Orders {
	display: flex;
	flex-direction: column;
	align-items: center;
	.CharlesTitle {
		margin: 6rem 0 4rem 0;
	}

	.tabs {
		display: flex;
		width: 100%;
		justify-content: center;
		padding: 0;
		margin-bottom: 4rem;
		list-style: none;
		background-color: white;

		li {
			font-family: $font-heading;
			text-transform: uppercase;
			font-size: 2rem;
			font-weight: 400;
			display: flex;
			flex-direction: column;
			margin: 0 4rem;

			.item__link {
				text-decoration: none;

				&::after {
					content: '';
					width: calc(100% + 4rem);
					margin-left: -2rem;
					height: 0.5rem;
					background-color: transparent;
					display: block;
					transform: rotate(-1deg);
				}

				&:hover {
					&::after {
						background-color: rgba($color-grey-1, 0.5);
					}
				}

				&.item__link--active {
					&::after {
						background-color: $color-gold-3;
					}

					&.item__link--unauthorized {
						color: rgba($color-black-2, 0.3);
						cursor: not-allowed;

						&::after {
							background-color: rgba($color-gold-3, 0.2);
						}
					}
				}
			}
		}
	}
	.OrderedProducts {
		.filters {
			display: flex;
			@media(max-width:$screen-xl ) {
				flex-direction: column;
			}
		}
		.List {
			.list__wrapper {
			 grid-template-columns: none;
				.list__header {
					.header__column {
						.header__content {
							justify-content: start;
							min-width: 10rem !important;
						}
					}
				}
				.list__body {
					.list__item   {
						.item__cell {
							&:last-child {
								@media(max-width: 72.8rem) {
									padding-left: 0;
								}
							}
						}
					}

				}
			}
		}
	}

	.Button {
		font-size: 1.4rem;
		@media(max-width: 60.063rem) {
			width: 30rem;
			margin-bottom: 1rem;
		}

		&.green {
			--color-r: 28;
			--color-g: 148;
			--color-b: 98;
		}
	}
}
