.CRUDList {
	width: 100%;
	padding: 0 4rem;
	margin-bottom: 6rem;

	.CharlesTitle {
		margin-bottom: 2rem;
	}

	.Button.new-item {
		margin-bottom: 2rem;
	}

	.message {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-size: 2.4rem;
		font-weight: 300;

		span[role="img"] {
			font-size: 4rem;
		}
	}
}