@import './../../../variables.scss';

.flexHorizontal {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.ProductsSummary {
    display: flex;
    flex-direction: column;
    font-family: $font-heading;
    padding: 0;

    .product__item {
        display: flex;
        flex-direction: row;
        font-size: 1.4rem;
        color: $color-black-2;
        border-bottom: 1px solid $color-grey-0;
        padding: 1.5rem 0;
        // padding: 1.5rem 2.5rem;
        // border: 1px solid $color-grey-0;
        // border-radius: $radius-default;
        // margin: 0 0 1rem 0;

        &:last-of-type {
            border-bottom: none;
        }

        &.product__item--disabled {

            .item__image,
            .item__body {
                opacity: 0.3;
                filter: grayscale(1);
            }
        }

        .item__image {
            img {
                width: 8rem;
                max-width: 8rem;
                height: auto;
                margin: 0 1.5rem 0 0;
            }
        }

        .item__body {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            justify-content: flex-start;
            align-items: flex-start;

            .product__title {
                display: flex;
                flex-direction: column;
                font-size: 2rem;

                .product__code {
                    color: $color-text-grey-0;
                    //font-weight: 500;
                    font-size: 1.2rem;
                    margin: 0 0 -0.8rem 0;
                }

                .product__name {
                    margin: 0;
                    font-weight: 400;
                    word-wrap: break-word;
                }
            }

            .product__promo {
                background-color: $color-green-0;
                color: white;
                padding: 0.3rem 1rem;
                font-size: 1.4rem;
                text-transform: uppercase;
                font-weight: 500;
                border-radius: $radius-default;
                display: inline-flex;
            }

            .product__notifications {
                color: $color-black-2;
                font-size: 1.6rem;
                font-weight: 300;

                .product__notification {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    margin: 1rem 0;
                    line-height: 1;

                    svg {
                        color: $color-red-0;
                        margin: 0 0.5rem 0 0;
                        font-size: 1.2rem;
                    }
                }
            }
        }

        .item__right {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .right__top {
                display: flex;
                justify-content: flex-end;

                .item__delete {
                    background-color: transparent;
                    border: none;
                    color: $color-red-1;
                    font-size: 1.6rem;
                    cursor: pointer;
                }
            }

            .quantity {
                font-size: 1.8rem;
                text-transform: uppercase;
                max-width: 20rem;
                width: 100%;
                display: inline-flex;
                flex-flow: column nowrap;
                justify-content: flex-start;
                align-items: flex-end;

                .quantity__breakdown {
                    margin: 0 1rem 0 0;

                    .quantity__promo {
                        color: $color-red-0;
                        margin: 0 0.5rem;

                        &::before {
                            content: '+';
                            margin: 0 0.5rem 0 0;
                        }
                    }

                    .quantity__ppq {
                        color: $color-text-grey-0;
                        margin: 0 0.5rem;

                        &::before {
                            content: '×';
                            margin: 0 0.5rem 0 0;
                        }
                    }

                    &::after {
                        content: '=';
                        margin: 0 0 0 0.5rem;
                    }
                }

                .quantity__total,
                .quantity__points {
                    span {
                        color: rgba($color-black-2, 0.6);
                    }
                }

                .quantity__points {
                    margin-top: 0.5rem;
                }
            }


            .mad-quantity__confirm {
                color: $color-red-0;
                border-radius: $radius-default;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                margin: 1rem 0 0 0;

                .confirm__options {
                    text-transform: uppercase;
                    margin: 1rem 0 0 0;

                    .confirm__option {
                        margin: 0 0 0 1rem;
                        padding: 1rem 1.5rem;
                        display: inline-flex;
                        justify-content: center;
                        cursor: pointer;
                        border-radius: $radius-default;
                        font-weight: 500;

                        &.confirm__option--accept {
                            color: $color-red-0;
                            background-color: white;
                            border: 1px solid $color-grey-0;
                        }

                        &.confirm__option--cancel {
                            color: white;
                            background-color: $color-red-0;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 650px) {
    .ProductsSummary {
        .product__item {
            display: flex;
            flex-direction: column;

            .item__image {
                img {
                    width: 100%;
                }
            }

            .item__body {
                margin-top: 1rem;
            }

            .item__right {
                margin-top: 1rem;
                align-items: flex-start;

                .mad-quantity__confirm {
                    flex-direction: row;
                }
            }
        }
    }
}