@import './../../variables.scss';

.List {
	.list__wrapper {
		grid-template-columns: none;
	}

	.list__header {
		display: grid;
		width: 100%;
		padding: 1rem 2.5rem;
		font-family: $font-heading;
		font-size: 2rem;
		text-transform: uppercase;
		background-color: white;

		.header__column {
			.header__content {
				min-width: 14rem !important;

				@media(min-width: $screen-m) {
					min-width: 16rem !important;
				}

				.left {
					font-size: 1.2rem;

					@media(min-width: $screen-m) {
						font-size: 1.4rem;
					}

					@media(min-width: $screen-xll) {
						font-size: 1.5rem;
					}
				}

				.right {
					.header__filter {
						svg {
							height: 1rem;

							@media(min-width: $screen-m) {
								height: 1.4rem;
							}

							@media(min-width: $screen-xll) {
								height: 1.5rem;
							}
						}

					}

					.header__sort {
						svg {
							height: 1rem;

							@media(min-width: $screen-m) {
								height: 1.4rem;
							}

							@media(min-width: $screen-xll) {
								height: 1.5rem;
							}
						}
					}
				}
			}
		}
	}

	.list__body {
		display: flex;
		padding: 0;
		flex-direction: column;
		border: 1px solid $color-grey-0;
		border-radius: $radius-default;
		font-weight: 300;
		cursor: pointer;

		.list__item {
			display: grid;
			align-items: center;
			border-bottom: 1px solid $color-grey-0;
			font-size: 1.6rem;
			font-family: $font-heading;
			width: 100%;
			padding: 1.5rem 2.5rem;

			&:first-of-type {
				border-top-right-radius: $radius-default;
				border-top-left-radius: $radius-default;
			}

			&:last-of-type {
				border-bottom: none;
				border-bottom-right-radius: $radius-default;
				border-bottom-left-radius: $radius-default;
			}

			// &:nth-child(even) {
			// 	background-color: rgba($color-grey-0, 0.2);

			// 	&:hover {
			// 		background-color: rgba($color-grey-0, 0.4);
			// 	}
			// }

			&:hover {
				background-color: rgba($color-grey-0, 0.1);
			}

			.item__cell {
				&:last-child {
					padding-left: 1.1rem;

					@media(min-width: 66.875) {
						padding-left: 0;
					}
				}

				font-size: 1.4rem;

				@media(min-width: $screen-m) {
					font-size: 1.5rem;
				}

				@media(min-width: $screen-xll) {
					font-size: 1.6rem;
				}
			}
		}
	}
}