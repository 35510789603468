@import './../../../variables.scss';

.ProductDetail {
	display: grid;
	grid-template-areas:
		'gallery details'
		'download details';
	grid-template-columns: 40rem 40rem;
	gap: 2rem;

	max-height: calc(100vh - 10rem);
	overflow-y: auto;

	font-family: $font-heading;

	.Article__name {
		white-space: inherit;
	}


	.product__image {
		grid-area: gallery;

		overflow: hidden;
		border: 1px solid $color-grey-0;
		border-radius: $radius-default;
	}

	.download__area {
		grid-area: download;

		h3 {
			text-transform: uppercase;
			font-size: 1.4rem;
			margin: 1rem 1rem .5rem 0;
			font-weight: 400;
		}

		>div {
			border-radius: $radius-default;
			display: flex;
			flex-wrap: wrap;
			gap: .7rem;
		}

		&__container {
			display: flex;
			flex-wrap: wrap;
			column-gap: 3rem;
			row-gap: 1rem;
		}

		&__images {}

		&__specifications {}

		&__flyers {}

		.download_button {
			position: relative;
			display: block;
			width: 6.3rem;
			height: 8.91rem;
			border: 1px solid rgba(0, 0, 0, .4);
			border-radius: .8rem;
			overflow: hidden;
			cursor: pointer;
			box-shadow: 0 0 7px 1px rgba(0, 0, 0, .15);
			transition: .2s;

			&:hover {
				border: 1px solid rgba(0, 0, 0, .6);
				box-shadow: 0 0 9px 1px rgba(0, 0, 0, .3);

				svg {
					padding: .8rem .6rem .4rem;
				}
			}

			svg {
				position: absolute;
				background: #222321;
				color: white;
				width: 3rem;
				height: 3rem;
				left: 1.5rem;
				top: 5rem;
				border-radius: $radius-100percent;
				padding: .6rem;
				transition: .2s;
			}

			&__image {
				width: unset;

				img {
					height: 100%;
				}

				svg {
					left: calc(50% - 1.5rem);
				}
			}

			&__flyer {
				img {
					position: absolute;
					top: -1px;
					bottom: -1px;
					left: -1px;
					right: -1px;
				}

				&__loading {
					img {
						opacity: .2;
					}

					svg {
						animation: spin .9s infinite ease-in-out;
					}
				}

				&__loading,
				&__error {
					box-shadow: initial;
					cursor: initial;
					background: rgba(0, 0, 0, .4);

					&:hover {
						border: 1px solid rgba(0, 0, 0, .4);
						box-shadow: none;

						svg {
							padding: .6rem;
							;
						}
					}
				}

				&__error {
					border: 1px solid #e53c3c;
					box-shadow: 0 0 9px 1px rgba(255, 0, 0, .3);

					svg {
						background: #903030;
					}
				}

				&__downloaded {
					box-shadow: 0 0 9px 1px rgba(0, 255, 153, 0.3);

					svg {
						background: #309050;
					}
				}

			}

			&__specification {}

			&__language {
				position: absolute;
				font-size: 1.5rem;
				text-decoration: none;
				padding: .1rem 1rem 0 .75rem
			}

		}
	}



	.product__details {
		grid-area: details;

		.product__id-and-brand {
			display: flex;

			.Article__id {
				font-size: 1.2rem;
				color: $color-text-grey-0;
				font-weight: 500;
			}

			.Article__brand {
				font-size: 1.2rem;
				margin: 0 0 0 1rem;
				color: $color-black-2;
			}
		}

		.Article__name {
			font-size: 3rem;
			font-weight: 400;
			padding: 0;
			margin: 0;
			word-wrap: break-word;
		}

		.product__group {
			font-size: 1.4rem;
			color: $color-text-grey-0;
			margin: 0;
			//font-weight: 300;
		}

		.product__shopcode {
			font-size: 1.4rem;
			color: $color-text-grey-0;
			margin: 0;
			font-weight: 300;

			.shopcode__level {
				&:not(:last-of-type)::after {
					content: '>';
					margin: 0 0.5rem;
				}
			}
		}

		.Allergens {
			margin: 0.5rem 0 0 0;
		}

		.ProductHistory {
			margin: 0.5rem 0 0 0;
		}

		.product__price-unit-weight {
			display: flex;
			justify-content: space-between;
			font-size: 1.6rem;
			margin: 1rem 0 0 0;
			color: $color-black-2;
		}

		.product__margin {
			display: flex;
			justify-content: space-between;
			font-size: 1.6rem;
			margin: 1rem 0 0 0;
			color: $color-black-2;
			line-height: 3rem;

			.product__margin-edit {
				padding-top: .2rem;
				padding-bottom: .2rem;
			}
		}

		.product__price {

			.price {}

			.unit {
				.unit__per {
					margin: 0 0.2rem;
				}
			}
		}

		.product__weight {}

		.product__description {
			font-family: $font-main;
			font-size: 1.5rem;
			margin: 2rem 0;
			line-height: 1.5;
			color: $color-black-2;
		}

		.ProductPromo {
			.promo__ribbon {
				font-size: 2rem;
				top: 3.5rem;
				left: -7rem;
				width: 26rem;
				z-index: 1;

				.promo__ribbon--subtext {
					font-size: 1.1rem;
					margin: -0.5rem 0 -0.1rem 0;
				}
			}
		}
	}
}

@media (max-width: 930px) {
	.ProductDetail {
		grid-template-areas:
			'gallery'
			'details'
			'download';
		grid-template-columns: 40rem;
	}
}

@media (max-width: 480px) {
	.ProductDetail {
		max-height: 100vh;
		max-width: 100vw;
		grid-template-columns: auto;

		.product__image {
			border-radius: 0;
			border: none;
		}

		.product__details {
			margin: 5rem 2rem 0;
			max-width: inherit;
		}

		.download__area__container {
			margin: 0 2rem 5rem;
		}
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(359deg);
	}
}
