@import './../../variables.scss';

.SplashScreen {
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	background-color: black;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.splash__logo {
		width: 100%;
		max-width: 50rem;
		padding: 0 4rem;

		svg {
			fill: white;

			g {
				display: none;
			}
		}
	}

	.splash__spinner {
		visibility: hidden;
		animation: showDelayed 0s 2s forwards;
		margin-top: 2rem;

		.Spinner {
			.circle {
				border-bottom-color: white;
				border-right-color: white;
			}

			.text {
				display: none;
			}
		}
	}
}