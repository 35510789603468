@import './../../variables.scss';

.Spinner {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.circle {
		width: 5rem;
		height: 5rem;
		border: 0.5rem solid $color-black-2;
		border-top-color: transparent;
		border-left-color: transparent;
		border-radius: 100%;
		animation: spinner 1000ms linear infinite;
	}

	.text {
		font-family: $font-heading;
		font-size: 1.6rem;
		margin: 2rem 0 0 0;
		font-weight: 400;
		text-transform: uppercase;
	}
}

.Spinner--small {
	.circle {
		width: 2rem;
		height: 2rem;
		border-width: 0.3rem;
	}
}

.Spinner--full {
	position: absolute;
	width: 100%;
	height: 100vh;

	.charles-logo {
		width: 50rem;
		margin: 0 0 5rem 0;
	}
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@media (max-width: 500px) {
	.Spinner--full {
		.charles-logo {
			width: 30rem;
		}
	}
}