@import './../../../variables.scss';

.PreOrder {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 100rem;
    margin: 0 0 5rem 0;
    position: relative;
    border: 1px solid rgb(236, 236, 236);
    border-radius: $radius-default;
    background-color: rgb(246, 246, 246);
    padding: 1.2rem;

    &.submitted {

        .articles,
        .submit {
            opacity: 0.3;
        }

        .ordered,
        .QuantityInput {
            filter: blur(2px);
        }
    }

    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 2.4rem 0;
        width: 100%;
        max-width: 100rem;

        h3 {
            font-family: $font-heading;
            text-transform: uppercase;
            font-weight: 400;
            font-size: 3rem;
            margin: 0;
        }

        .deadline {
            margin-right: 1.2rem;
            font-family: $font-heading;
            font-size: 1.6rem;
            font-weight: 300;

            span {
                font-weight: 400;
            }
        }

        .section_info {
            font-family: $font-heading;
            font-size: 1.6rem;
            font-weight: 300;
            margin-right: 1.2rem;
            color: #C1222F;
        }

        .delivery-week {
            font-size: 1.8rem;
            font-family: $font-heading;
            text-transform: uppercase;
            color: white;
            background-color: $color-blue-0;
            border-radius: 0.6rem;
            padding: 0.2rem 0.6rem;
            width: auto;
            text-align: center;
        }

        .details {
            display: flex;
            flex-direction: column;
        }
    }

    .articles {
        width: 100%;
        border: 1px solid rgb(236, 236, 236);
        border-radius: $radius-default;
        padding: 0.6rem;
        margin-bottom: 1.2rem;
        background-color: white;

        .list {
            display: flex;
            flex-wrap: wrap;
            height: auto;
            margin-bottom: .5rem;

            @media(min-width: $screen-m) {
                flex-wrap: nowrap;
                //justify-content: space-between;
            }

            &>* {
                width: 100%;

                @media(min-width: $screen-m) {
                    width: auto;
                }

            }

            .ArticleImage {

                max-height: 160px;

                @media(min-width: $screen-m) {
                    width: 30%;
                }

                @media(min-width: $screen-xl) {
                    width: 20%;
                }
            }

            .bottom-container {
                flex: 1;
            }

            // .bottom {
            // 	@media(min-width: $screen-m) {
            // 		position: absolute;
            // 		right: .5rem;
            // 		bottom: 2rem;
            // 	}
            // }

            .order {
                .Badge {
                    // position: absolute;

                    @media(min-width: $screen-m) {
                        top: 8rem;
                    }
                }
            }
        }

        .ProductItem:not(:last-of-type) {
            margin-bottom: 0.6rem;
        }
    }

    .Button {
        color: $color-black-2;
        font-size: 1.8rem;
        padding: 1rem 2rem;

        .content {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .bottom {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: end;

        .saved {
            margin-left: 1.6rem;
            color: $color-green-1;
            font-size: 1.6rem;
            font-family: $font-heading;
            width: 100%;
        }
    }

    .error {
        margin-bottom: 1.4rem;
        font-size: 1.8rem;
        color: $color-red-0;
        font-family: $font-heading;
    }
}