@import './../../variables.scss';

.TradeFairPointsOverview {
    margin: 0 1.2rem 8.2rem 1.2rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    img.logo {
        width: 24rem;
        margin: 2.4rem;
    }

    img.spaarsaldo {
        width: 100%;
        max-width: 100rem;
        margin: 2.4rem;
    }

    h1 {
        font-size: 3.3rem;
        font-weight: 700;
        margin: 2.4rem;
        text-transform: uppercase;
    }

    ul {
        font-size: 2rem;

        li {
            margin: 1rem;
        }
    }

    .points {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1.2rem;
        font-size: 1.5rem;
        //margin: 4rem;
        flex-wrap: wrap;
        flex-Flow: 'column nowrap';

        &.danger .Coin {
            color: red;
        }
    }

    .text {
        font-size: 1.2rem;
        margin: 0.4rem;
        text-align: center;

    }

    .title {
        font-size: 2.4rem;
        font-weight: 700;
        text-transform: uppercase;
    }

    .text_block {
        margin: 2rem;
    }


    // .text_title {
    //     font-size: 2.4rem;
    //     font-weight: 700;
    //     margin: 2.4rem;
    //     text-transform: uppercase;
    // }


    .carousel {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: center;
        gap: 2.5rem;
        margin: 2rem auto;

        img {
            width: auto;
            height: 30rem;
        }
    }

    .submit-order {
        display: block;
        text-align: center;
        background-color: white;
        width: 100%;
        position: fixed;
        bottom: 0;
        z-index: 1000;
        padding: 1.35rem;
        box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);

        @media screen and (min-width: 650px) {
            position: relative;
            box-shadow: none;

        }

        .warnings {
            margin-bottom: 1rem;
            font-weight: 400;
            font-size: 1.2rem;

            &.warn {
                color: red;
            }
        }

        .errors {
            color: red;
            //font-size: 1.6rem;
            font-size: 1.2rem;

            span {
                padding-top: 1rem;
                display: block;
            }
        }
    }

    .rewards {
        display: flex;
        flex-flow: row wrap;
        gap: 5rem;
        margin-top: 1.6rem;
        align-self: center;
        justify-content: center;
    }

    @media (max-width: 650px) {
        .rewards {
            margin-bottom: 24rem;
        }
    }

    .footer {
        margin-top: 3rem;
        font-size: 1.4rem;

        .rules {
            margin-top: 5rem;
            font-size: 1.2rem;
            display: flex;
            gap: 4rem;


        }

    }
}