@import './../../../variables.scss';

.FoodEventTickets {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 1.6rem;
	font-family: $font-main;

	.container {
		max-width: 87.6rem;
		margin: 1.2rem;

		.BannerContainer {

			.BannerImage {
				width: 100%;
			}
		}

		>div {
			margin-bottom: 3.6rem;

			.bold {
				font-weight: 700;
			}
		}
	}

	h2 {
		font-size: 2.4rem;
		font-weight: 500;
		margin: 0 0 1.2rem 0;

		&::after {
			content: none
		}
	}

	.tickets {
		display: flex;
		flex-wrap: wrap;
		gap: 0.6rem;

		.ticket {
			padding: 1rem;
			border-radius: 0.3rem;
			font-size: 1.8rem;
			font-family: $font-heading;
			width: 12rem;
			position: relative;
			color: white;
			display: flex;
			align-items: center;
			justify-content: center;
			background-image: url('./../../../images/coupon.png');
			z-index: 1;

			&.winning {
				transform: scale(1.3) rotate(-3deg);
				box-shadow: 0 0 1.5rem 0.5rem rgba(0, 0, 0, .2);
				z-index: 4;
				position: relative;
				background: none;

				&::before {
					content: '';
					box-shadow: inset 0 0 0rem 2rem rgba(0, 0, 0, .15);
					width: 100%;
					height: 100%;
					position: absolute;
					z-index: -1;
				}

				&::after {
					content: '';
					background-image: url('./../../../images/coupon.png');
					z-index: -1;
					width: 100%;
					height: 100%;
					position: absolute;
				}
			}

			&.losing {
				opacity: 0.5;
			}
		}
	}

}